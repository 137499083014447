import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "../App";
import Ati from "../Ati";
import Cehia from "../Cehia";
import Cookies from "../Cookies";
import Decese from "../Decese";
import FeedAlt from "../FeedAlt";
import Globe from "../Globe";
import Incidenta from "../Incidenta";
import Romania from "../Romania";
import SearchDaily from "../SearchDaily";
import SurseOficiale from "../SurseOficiale";
import Teste from "../Teste";
import VacEu from "../VacEu";
import ChartBook from "../ChartBook";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={() => <Romania lang="ro" />} exact={true} />
        <Route
          path="/en/"
          component={() => <Romania lang="en" />}
          exact={true}
        />
        <Route path="/cz/" component={() => <Cehia lang="en" />} exact={true} />
        <Route
          path="/chart/"
          component={() => <ChartBook lang="ro" />}
          exact={true}
        />

        <Route
          path="/en/eu/"
          component={() => (
            <Globe
              actv="globe"
              file="weekly_results"
              lang="en"
              typeText="cases"
              titleText="Cases"
              min={1000}
              max={500000}
              minPm={100}
              maxPm={4000}
              eumin={5000}
              eumax={300000}
              euminPm={500}
              eumaxPm={9000}
              indicator="cases"
            />
          )}
          exact={true}
        />
        <Route
          path="/eu/"
          component={() => (
            <Globe
              actv="globe"
              file="weekly_results"
              lang="ro"
              typeText="cazuri"
              titleText="Cazuri"
              min={1000}
              max={500000}
              minPm={100}
              maxPm={4000}
              eumin={5000}
              eumax={300000}
              euminPm={500}
              eumaxPm={9000}
              indicator="cases"
            />
          )}
          exact={true}
        />

        <Route
          path="/en/eu_deaths/"
          component={() => (
            <Globe
              actv="globe_deaths"
              file="weekly_results"
              lang="en"
              typeText="deaths"
              titleText="Deaths"
              min={10}
              max={10000}
              minPm={1}
              maxPm={100}
              eumin={10}
              eumax={10000}
              euminPm={1}
              eumaxPm={150}
              indicator="deaths"
            />
          )}
          exact={true}
        />
        <Route
          path="/eu_deaths/"
          component={() => (
            <Globe
              actv="globe_deaths"
              file="weekly_results"
              lang="ro"
              typeText="decese"
              titleText="Decese"
              min={10}
              max={10000}
              minPm={1}
              maxPm={100}
              eumin={10}
              eumax={10000}
              euminPm={1}
              eumaxPm={150}
              indicator="deaths"
            />
          )}
          exact={true}
        />

        <Route
          path="/en/vaccine_eu/"
          component={() => <VacEu lang="en" />}
          exact={true}
        />
        <Route
          path="/vaccine_eu/"
          component={() => <VacEu lang="ro" />}
          exact={true}
        />

        {/* <Route
          path="/en/emergency-state/"
          component={() => <StareUrgenta lang="en" />}
        />
        <Route
          path="/stare-urgenta/"
          component={() => <StareUrgenta lang="ro" />}
          exact={true}
        /> */}

        <Route
          path="/presa/"
          component={() => <App lang="ro" />}
          exact={true}
        />
        <Route
          path="/presa/:judet"
          component={() => <App lang="ro" />}
          exact={true}
        />
        <Route
          path="/en/local-media/"
          component={() => <App lang="en" />}
          exact={true}
        />
        <Route
          path="/en/local-media/:judet"
          component={() => <App lang="en" />}
          exact={true}
        />

        <Route
          path="/despre/"
          component={() => <SurseOficiale lang="ro" />}
          exact={true}
        />
        <Route
          path="/en/about/"
          component={() => <SurseOficiale lang="en" />}
          exact={true}
        />

        <Route path="/ati/" component={() => <Ati lang="ro" />} exact={true} />
        <Route
          path="/en/ati/"
          component={() => <Ati lang="en" />}
          exact={true}
        />
        <Route
          path="/teste/"
          component={() => <Teste lang="ro" />}
          exact={true}
        />
        <Route
          path="/en/teste/"
          component={() => <Teste lang="en" />}
          exact={true}
        />
        <Route
          path="/decese/"
          component={() => <Decese lang="ro" />}
          exact={true}
        />
        <Route
          path="/en/decese/"
          component={() => <Decese lang="en" />}
          exact={true}
        />
        <Route
          path="/incidenta/"
          component={() => <Incidenta lang="ro" />}
          exact={true}
        />
        <Route
          path="/en/incidenta/"
          component={() => <Incidenta lang="en" />}
          exact={true}
        />

        <Route
          path="/cookies/"
          component={() => <Cookies lang="ro" />}
          exact={true}
        />
        <Route
          path="/en/cookies/"
          component={() => <Cookies lang="en" />}
          exact={true}
        />

        <Route path="/ro/" component={Romania} exact={true} />

        <Route path="/test10/" component={FeedAlt} exact={true} />
        <Route path="/search-daily/" component={SearchDaily} exact={true} />
      </Switch>
    </BrowserRouter>
  );
}
