import React, { useState, useEffect } from "react";
import moment from "moment";
import details from "../data/details.json";

import {
  LineChart,
  BarChart,
  Bar,
  Legend,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";

export default function LineChartComponent(props) {
  // console.log("props.data", props.data.slice(-53, props.data.length - 1));
  const [localData, setLocalData] = useState();
  const [maxVal, setMaxVal] = useState(0);
  const [maxDeathsVal, setMaxDeathsVal] = useState(0);
  useEffect(() => {
    let localColl = props.data;

    setLocalData(localColl);
  }, [props]);
  useEffect(() => {
    if (localData) {
      let max = Math.max(...localData.map((itm) => parseInt(itm.weekly_count)));
      let maxDeaths = Math.max(
        ...localData.map((itm) => parseInt(itm.weekly_count_deaths))
      );
      setMaxVal(max);
      setMaxDeathsVal(maxDeaths);
    } else {
      setMaxVal(0);
      setMaxDeathsVal(0);
    }
  }, [localData]);
  // console.log(localData);
  return (
    <div
      style={{
        // marginLeft: -20,
        height: window.innerWidth > 500 ? 400 : 300,
        display: "flex",
        justifyContent: "left",
        width: "100%",
      }}
    >
      {props.data && props.data.length > 0 ? (
        <ResponsiveContainer
          // style={{ marginLeft: -10 }}
          width="100%"
          height="100%"
        >
          <LineChart
            // width={500}
            height={400}
            data={props.data.sort((a, b) =>
              a.year_week > b.year_week ? 1 : -1
            )}
            margin={{
              top: 0,
              right: -20,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              itemStyle={{ fontSize: 14 }}
              formatter={(lbl) => {
                return Intl.NumberFormat().format(lbl);
              }}
            />
            <XAxis
              key={Math.random()}
              hide={false}
              dataKey="realDate"
              tick={{ fontSize: 12 }}
              interval={24}
              tickMargin={10}
            />
            <YAxis
              hide={false}
              tick={{ fontSize: 11 }}
              domain={[0, "dataMax"]}
              yAxisId="left"
              orientation="left"
              stroke="#DC143C"
              tickFormatter={(tick) =>
                Intl.NumberFormat().format((tick / 1000).toFixed(1)) + "k"
              }
            />
            <YAxis
              hide={false}
              tick={{ fontSize: 11 }}
              domain={[0, maxDeathsVal * 4]}
              tickMargin={10}
              yAxisId="right"
              orientation="right"
              tickFormatter={(tick) =>
                Intl.NumberFormat().format((tick / 1000).toFixed(1)) + "k"
              }
            />

            <Line
              isAnimationActive={false}
              dataKey="weekly_count"
              yAxisId="left"
              name={props.lang === "ro" ? "cazuri" : "cases"}
              fill="Crimson"
              stroke="Crimson"
              strokeWidth={1.5}
              dot={false}
            />
            <Line
              isAnimationActive={false}
              dataKey="weekly_count_deaths"
              yAxisId="right"
              name={props.lang === "ro" ? "decese" : "deaths"}
              fill="#333"
              stroke="#333"
              strokeWidth={1.5}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      ) : null}
    </div>
  );
}
