import moment from "moment";

export const getAnd = `
query getJudet($time: String!, $wone: String!, $wtwo: String!, $spacer: Int!) {
  articles(distinct_on: isoDate, where: {_and: {title: {_ilike: $wone}}, isoDate: {_gte: $time}, title: {_ilike: $wtwo}}, order_by: {isoDate: desc}, limit: $spacer) {
    title
    isoDate
    corona
    judet 
    link
  }
}
`;

export const getNotIn = `
query getJudet($wone: String!, $wtwo: String!, $spacer: Int!) {
  articles(distinct_on: isoDate, where: {title: {_ilike: $wone, _nilike: $wtwo}}, order_by: {isoDate: desc}, limit: $spacer) {
    link
    title
    isoDate
    judet
    corona
  }
}
`;

export const getOrTwo = `
query getJudet($wone: String!, $wtwo: String!, $spacer: Int!) {
  articles(where: {_or: [{title: {_ilike: $wone}}, {title: {_ilike: $wtwo}}]}, order_by: {isoDate: desc}, distinct_on: isoDate, limit: $spacer) {
    link
    title
    isoDate
    judet
    corona
  }
}
`;

export const getOrTwoNot = `
query getJudet($wone: String!, $wtwo: String!, $wthree: String!, $spacer: Int!) {
  articles(where: {_or: [{title: {_ilike: $wone, _nilike: $wthree}}, {title: {_ilike: $wtwo, _nilike: $wthree }}]}, order_by: {isoDate: desc}, distinct_on: isoDate, limit: $spacer) {
    link
    title
    isoDate
    judet
    corona
  }
}
`;

export const oneWord = `
query getJudet($time: String!, $wone: String!, $spacer: Int!) {
  articles(distinct_on: isoDate, 
    where: {isoDate: {_gte: $time}, contentSnippet: {_ilike: $wone}}
    
    , order_by: {isoDate: desc},  limit: $spacer) {
    title
    isoDate
    judet
    link
  }
}
`;
