import moment from "moment";

export const allCazuri = `query GetCazuri {
  cazuri {
    cazuri_total
    judet
    lat
    long
  }
}`;

export const generalQuery = `query GetArticles($spacer: Int!) {
  articles(
    where: { corona: { _eq: "true" }}
    order_by: { isoDate: desc }
    limit: $spacer
    distinct_on: isoDate
  ) {
    corona
    id
    isoDate
    judet
    link      
    title
  }
}`;

export const generalHistory = `query GetHistory($tstart: String!, $tend: String!) {
  articles_aggregate(where: {isoDate: {_gte: $tstart, _lte: $tend}, corona: {_eq: "true"}}, distinct_on: title) {
    aggregate {
      count
    }
  }
}`;
export const judetHistory = `query GetHistory($tstart: String!, $tend: String!, $judet: String!) {
  articles_aggregate(where: {isoDate: {_gte: $tstart, _lte: $tend}, corona: {_eq: "true"}, judet: {_eq: $judet}}, distinct_on: title) {
    aggregate {
      count
    }
  }
}`;
export const queryArticles = `query GetArticles($judet: String!, $spacer: Int!) {
  articles(
    where: { corona: { _eq: "true" }, judet: {_eq: $judet} }
    order_by: { isoDate: desc }
    limit: $spacer
    distinct_on: isoDate
  ) {
    corona
    id
    isoDate
    judet
    link      
    title
  }
}`;

export const queryTotalJudete = `
query getJudet {
  articles_aggregate(distinct_on: isoDate, where: {corona: {_eq: "true"}, isoDate: {_gte: "${moment()
    .subtract(24, "hours")
    .format("X")}"}}, order_by: {isoDate: desc}) {
    aggregate {
      count
    }
  }
}
`;

export const queryJudete = `
query getJudet($judet: String!) {
  articles_aggregate(distinct_on: isoDate, where: {corona: {_eq: "true"}, judet: {_eq: $judet}, isoDate: {_gte: "${moment()
    .subtract(24, "hours")
    .format("X")}"}}, order_by: {isoDate: desc}) {
    aggregate {
      count
    }
  }
}
`;

export const updateTime = `
query getUpdateTime($sectiune: String!) {
  updated(where: {sectiune: {_eq: $sectiune}}) {
    id
    updated
  }
}
`;