import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Refresh from "@material-ui/icons/Refresh";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./App.css";
import ChipMenu from "./components/ChipMenu";
import Header from "./components/common/Header";
import EuroBarVac from "./components/EuroBarVac.js";
import EuroMapVac from "./components/EuroMapVac.js";
import LegendMy from "./components/LegendMy";
import updateTime from "./data/updated.json";

import tariList from "./sources/tari.json";

moment.locale("ro");

let europeList = [
  "Belgium",
  "Bulgaria",
  "Czech Republic",
  "Denmark",
  "Germany",
  "Estonia",
  "Ireland",
  "Greece",
  "Spain",
  "France",
  "Croatia",
  "Italy",
  "Cyprus",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Hungary",
  "Malta",
  "Netherlands",
  "Austria",
  "Poland",
  "Portugal",
  "Romania",
  "Slovenia",
  "Slovakia",
  "Finland",
  "Sweden",
  "Iceland",
  "Liechtenstein",
  "Norway",
];

let tariEurope = [];
tariList.forEach(
  (item) => europeList.includes(item.en) && tariEurope.push(item)
);
// console.log(tariEurope);
const marksEng = [
  {
    value: 0,
    label: "0",
  },
  {
    value: -7,
    label: "-7",
  },
  {
    value: -14,
    label: "-14",
  },
  {
    value: -21,
    label: "-21",
  },
  {
    value: -28,
    label: "-28",
  },
];

function VacEu(props) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const [ttip, setTtip] = useState("");

  const [clicked, setClicked] = useState("None");
  const [mapSwitch, setMapSwitch] = useState(false);

  const [chartData, setChartData] = useState([]);

  const [allData, setAllData] = useState([]);

  const getData = async () => {
    await fetch(`/data/vaccine_weekly_eu.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        setAllData(data);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    clicked !== "None" &&
      ReactGA.event({
        category: "Europe",
        action: clicked,
      });

    setChartData([]);
    clicked === "None"
      ? setChartData(allData.filter((item) => item.country === "total"))
      : setChartData(allData.filter((item) => item.country === clicked));
  }, [clicked, allData]);

  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <Typography
        variant="body2"
        style={{
          fontSize: 12,
          backgroundColor: "#dd1b14",
          color: "white",
          padding: 4,
          width: "100%",
        }}
      >
        {props.lang === "ro"
          ? "Această aplicație folosește cookies pentru a colecta statistici de utilizare. Prin folosirea aplicației îți exprimi acordul cu "
          : "This application uses cookies to collect usage statistics. By using the application you accept "}
        <Link
          style={{ color: "white", margin: 0 }}
          to={props.lang === "ro" ? "/cookies/" : "/en/cookies/"}
        >
          {props.lang === "ro"
            ? "politica noastră privind datele personale"
            : "our personal data policy"}
        </Link>
        .
      </Typography>

      <Container style={{ maxWidth: "800px" }}>
        <Header />
        <ChipMenu active={"vac_eu"} lang={props.lang} />

        <Container
          style={{
            maxWidth: "800px",
            textAlign: "left",
            padding: 0,
            marginTop: 20,
          }}
        >
          <Typography style={{ marginBottom: 10, marginTop: 30 }} variant="h5">
            <strong>
              {props.lang === "ro"
                ? "Vaccinarea în UE"
                : "Vaccination in the EU"}
            </strong>
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 20,
              marginTop: 5,
              color: "grey",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro" ? "Actualizat: " : "Updated: "}{" "}
            {updateTime.europa}
          </Typography>

          <Typography
            style={{ fontWeight: 400, marginTop: 0, marginBottom: 0 }}
            variant="subtitle1"
          >
            {props.lang === "ro" ? (
              <span>
                <strong>Harta curentă: </strong>procentul de persoane vaccinate
                cu ambele doze în UE
              </span>
            ) : (
              <span>
                <strong>Current map: </strong>percent of fully vaccinated people
                in the EU
              </span>
            )}
          </Typography>

          <div
            style={{
              width: window.innerWidth > 500 ? "100%" : "108%",
              marginLeft: window.innerWidth > 500 ? "0%" : "-4%",
            }}
          >
            <Fragment>
              <EuroMapVac
                data={allData}
                setClicked={setClicked}
                mapSwitch={mapSwitch}
                setTooltipContent={setTtip}
                lang={props.lang}
              />
              <ReactTooltip>{ttip}</ReactTooltip>
            </Fragment>
          </div>
        </Container>

        <div style={{ width: "100%", marginTop: 30 }}></div>
        <div style={{ marginTop: 10, marginBottom: 0 }}>
          <strong
            style={{
              padding: 4,
              paddingRight: 10,
              paddingLeft: 10,
              borderRadius: 4,
              backgroundColor: "Crimson",
              color: "white",
              marginTop: 10,
            }}
          >
            {allData.length > 0 &&
              moment(allData[0].week.replace("W", ""), "YYYY-W").format(
                "DD/MM"
              )}{" "}
            -{" "}
            {allData.length > 0 &&
              moment(allData[0].week.replace("W", ""), "YYYY-W")
                .add(7, "days")
                .format("DD/MM 'YY")}
          </strong>
        </div>
        <Typography
          style={{
            color: "#4F4F4F",
            lineHeight: "18px",
            marginBottom: 0,
            marginTop: 10,
          }}
          variant="subtitle2"
        >
          {props.lang === "ro"
            ? "- click pe orice țară pentru detalii -"
            : "- click any country for more details -"}
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={mapSwitch}
              onChange={() => setMapSwitch(!mapSwitch)}
              name="cpm"
              color="secondary"
            />
          }
          label={
            props.lang === "ro"
              ? "vaccinați cu cel puțin o doză"
              : "vaccinated with at least one dose"
          }
          style={{ marginBottom: 0, marginTop: 0 }}
        />

        <Divider style={{ marginBottom: 50, marginTop: 40 }} />

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "left",
            marginBottom: 0,
          }}
        >
          <FormControl style={{ marginBottom: 10, marginTop: 0 }}>
            <Select
              style={{
                fontSize: "1.5rem",
                fontWeight: 700,
                borderBottom: 0,
                marginRight: 10,
              }}
              labelId="jduet-label"
              disableUnderline
              color="secondary"
              id="jduet-select"
              value={clicked}
              onChange={(event) => setClicked(event.target.value)}
            >
              <MenuItem key="None" value="None">
                {props.lang === "ro" ? (
                  <span>Toată Uniunea</span>
                ) : (
                  <span>Entire EU</span>
                )}
              </MenuItem>
              {tariEurope
                .sort((a, b) =>
                  props.lang === "ro"
                    ? a.ro < b.ro
                      ? -1
                      : 1
                    : a.en > b.en
                    ? 1
                    : -1
                )
                .map((j) => (
                  <MenuItem key={j.en} value={j.en}>
                    {props.lang === "ro" ? j.ro : j.en}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {clicked !== "None" ? (
            <Chip
              onClick={() => setClicked("None")}
              style={{
                backgroundColor: "Crimson",
                color: "white",
                marginBottom: 10,
                marginTop: 0,
                marginLeft: 0,
                border: 0,
                paddingLeft: 2,
                paddingRight: 2,
              }}
              label={<strong>RESET</strong>}
              icon={<Refresh style={{ color: "white" }} />}
            />
          ) : (
            <Chip
              style={{
                color: "#555",
                marginBottom: 10,
                marginTop: 0,
                marginLeft: 0,
                border: 0,
                paddingLeft: 2,
                paddingRight: 2,
              }}
              label={
                <strong>
                  {props.lang === "ro" ? "ALEGE ȚARA" : "SELECT COUNTRY"}
                </strong>
              }
            />
          )}
        </div>
        <Typography
          style={{
            fontWeight: 400,
            marginBottom: 20,
            marginTop: 0,
            color: "grey",
            textAlign: "left",
          }}
          variant="subtitle2"
        >
          {props.lang === "ro" ? "Actualizat: " : "Updated: "}
          {updateTime.europa}
        </Typography>

        <div style={{ textAlign: "left" }}>
          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 30,
              marginTop: 10,
              textAlign: "left",
            }}
            variant="subtitle1"
          >
            {props.lang === "ro" ? (
              <span>
                <strong>Grafic: </strong>evoluția vaccinării în țara curentă per
                tip de vaccin și doză (date săptămânale)
              </span>
            ) : (
              <span>
                <strong>Chart: </strong>
                evolution of vaccination in the current country by vaccine type
                and dose (weekly data)
              </span>
            )}
          </Typography>
          {chartData && chartData.length > 0 ? (
            <EuroBarVac
              updated={updateTime.europa}
              data={chartData}
              lang={props.lang}
            />
          ) : (
            <strong>
              {props.lang === "ro"
                ? "0 cazuri sau informații indisponibile"
                : "0 cases or no data available"}
            </strong>
          )}

          <div style={{ marginTop: 20 }}>
            <LegendMy
              style={{ marginTop: 0, marginBottom: 0 }}
              content={[
                {
                  name: props.lang === "ro" ? "Pfizer (1)" : "Pfizer (1)",
                  color: "#16a085",
                },
                {
                  name: props.lang === "ro" ? "Pfizer (2)" : "Pfizer (2)",
                  color: "#1abc9c",
                },
                {
                  name: props.lang === "ro" ? "Moderna (1)" : "Moderna (1)",
                  color: "#f39c12",
                },
                {
                  name: props.lang === "ro" ? "Moderna (2)" : "Moderna (2)",
                  color: "#f1c40f",
                },
                {
                  name: props.lang === "ro" ? "A. Zeneca (1)" : "A. Zeneca (1)",
                  color: "#8e44ad",
                },
                {
                  name: props.lang === "ro" ? "A. Zeneca (2)" : "A. Zeneca (2)",
                  color: "#9b59b6",
                },
                {
                  name:
                    props.lang === "ro"
                      ? "Johnson & Johnson"
                      : "Johnson & Johnson",
                  color: "#3498db",
                },
                {
                  name: "Sputnik (1)",
                  color: "#c0392b",
                },
                {
                  name: "Sputnik (2)",
                  color: "#e74c3c",
                },
                {
                  name: "Beijing CNBG (1)",
                  color: "#22a6b3",
                },
                {
                  name: "Beijing CNBG (2)",
                  color: "#7ed6df",
                },
              ]}
            />
          </div>
        </div>
      </Container>

      <Container style={{ maxWidth: 800, textAlign: "left" }}>
        <Divider style={{ marginTop: 30, marginBottom: 30 }} />

        <Typography
          style={{ marginTop: 20, marginBottom: 10, color: "grey" }}
          variant="subtitle2"
        >
          <strong>{props.lang === "ro" ? "Surse:" : "Sources:"}</strong>
        </Typography>
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="https://www.ecdc.europa.eu/en/novel-coronavirus-china"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          1. European Centre for Disease Prevention and Control
        </Typography>

        <Divider style={{ marginBottom: 30, marginTop: 30 }} />

        <div style={{ textAlign: "center", marginBottom: 40 }}>
          <Typography variant="subtitle2">Tech by</Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://addictad.ro"
          >
            <img
              style={{ height: 16, marginTop: 5 }}
              src="/images/logo-400px.png"
              alt=""
            />
          </a>
        </div>
      </Container>
    </div>
  );
}

export default VacEu;
