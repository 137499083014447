import moment from "moment";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
// import teste_amenzi_apeluri from "../../data/teste_amenzi_apeluri.json";

// teste_amenzi_apeluri.forEach(
//   (itm) => (itm.dataOk = moment(itm.data, "MM/DD/YYYY").format("DD/MM 'YY"))
// );

export default function ChartBarTeste(props) {
  const [localData, setLocalData] = React.useState([]);
  React.useEffect(() => {
    let l_data = [...props.data];
    l_data.forEach(
      (itm) =>
        (itm.dataOk = moment(
          itm.data,
          itm.data.includes("-") === true ? "YYYY-MM-DD" : "MM/DD/YYYY"
          // "MM/DD/YYYY"
        ).format("DD/MM 'YY"))
    );
    setLocalData(l_data);
  }, [props]);
  console.log(props);
  // console.log(localData);
  return (
    <div
      style={{
        marginLeft: -10,
        height: window.innerWidth > 500 ? 300 : 300,
        display: "flex",
        justifyContent: "left",
      }}
    >
      <ResponsiveContainer
        style={{ marginLeft: -20 }}
        width="100%"
        height="100%"
      >
        <LineChart
          width="100%"
          height={300}
          data={
            localData.sort((a, b) => (a.data_unix < b.data_unix ? -1 : 1)) || []
          }
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={{ fontSize: 12 }} dataKey="dataOk" tickMargin={5} />
          <YAxis
            allowDataOverflow={true}
            // domain={[0, 45000]}
            tick={{ fontSize: 12 }}
            tickMargin={5}
          />
          <Tooltip itemStyle={{ fontSize: 14 }} isAnimationActive={false} />
          {/* <Bar
            dataKey="ati"
            name={props.lang === "ro" ? "teste pe zi" : "tests per day"}
            fill={props.color}
            isAnimationActive={false}
            barSize={window.innerWidth < 500 ? 15 : 28}
            stackId="a"
          /> */}
          <Line
            dot={false}
            // key={Math.random()}
            isAnimationActive={false}
            type="monotone"
            dataKey="incid"
            name={
              props.lang === "ro"
                ? "incidența per 1000 locuitori"
                : "cases per 1000 inhabitants"
            }
            stroke="Crimson"
            strokeWidth={2}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
