import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function VariantsBar(props) {
  return (
    <div
      style={{
        height: window.innerWidth > 500 ? 350 : 350,
        display: "flex",
        justifyContent: "left",
      }}
    >
      {props.data.length > 0 && (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width="100%"
            height={350}
            data={props.data.slice(-30, props.data.length)}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tick={{ fontSize: 12 }} dataKey="dataOk" />
            <YAxis
              tick={{ fontSize: 12 }}
              tickFormatter={(tick) =>
                Intl.NumberFormat().format(tick.toFixed(1))
              }
            />
            <Tooltip
              itemStyle={{ fontSize: 14 }}
              isAnimationActive={false}
              formatter={(lbl) => {
                return Intl.NumberFormat().format(lbl);
              }}
            />
            <ReferenceLine y={0} stroke="#cbcbcb" />

            <Bar
              isAnimationActive={false}
              type="monotone"
              dataKey="pfizer_1"
              stackId="1"
              stroke="#16a085"
              fill="#16a085"
              name="Pfizer (1)"
            />
            <Bar
              isAnimationActive={false}
              type="monotone"
              dataKey="pfizer_2"
              stackId="1"
              stroke="#1abc9c"
              fill="#1abc9c"
              name="Pfizer (2)"
            />

            <Bar
              isAnimationActive={false}
              type="monotone"
              dataKey="moderna_1"
              stackId="1"
              stroke="#f39c12"
              fill="#f39c12"
              name="Moderna (1)"
            />
            <Bar
              isAnimationActive={false}
              type="monotone"
              dataKey="moderna_2"
              stackId="1"
              stroke="#f1c40f"
              fill="#f1c40f"
              name="Moderna (2)"
            />

            <Bar
              isAnimationActive={false}
              type="monotone"
              dataKey="azeneca_1"
              stackId="1"
              stroke="#8e44ad"
              fill="#8e44ad"
              name="Astra-Zeneca (1)"
            />
            <Bar
              isAnimationActive={false}
              type="monotone"
              dataKey="azeneca_2"
              stackId="1"
              stroke="#9b59b6"
              fill="#9b59b6"
              name="Astra-Zeneca (2)"
            />
            <Bar
              isAnimationActive={false}
              type="monotone"
              dataKey="johnson"
              stackId="1"
              stroke="#3498db"
              fill="#3498db"
              name="Johnson & Johnson"
            />
            <Bar
              isAnimationActive={false}
              type="monotone"
              dataKey="pfizer_3"
              stackId="1"
              stroke="rgba(26,188,156,0.4)"
              fill="rgba(26,188,156,0.4)"
              name="Pfizer (3)"
            />
            <Bar
              isAnimationActive={false}
              type="monotone"
              dataKey="moderna_3"
              stackId="1"
              stroke="rgba(241,196,15,0.4)"
              fill="rgba(241,196,15,0.4)"
              name="Moderna (3)"
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
