import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import "./App.css";
import Header from "./components/common/Header";

// ReactGA.initialize("UA-1029715-12");
// ReactGA.pageview(window.location.pathname);

export default function SurseOficiale(props) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <Typography
        variant="body2"
        style={{
          fontSize: 12,
          backgroundColor: "#dd1b14",
          color: "white",
          padding: 4,
          width: "100%",
        }}
      >
        {props.lang === "ro"
          ? "Această aplicație folosește cookies pentru a colecta statistici de utilizare. Prin folosirea aplicației îți exprimi acordul cu "
          : "This application uses cookies to collect usage statistics. By using the application you accept "}
        <Link
          style={{ color: "white", margin: 0 }}
          to={props.lang === "ro" ? "/cookies/" : "/en/cookies/"}
        >
          {props.lang === "ro"
            ? "politica noastră privind datele personale"
            : "our personal data policy"}
        </Link>
        .
      </Typography>
      <Container style={{ maxWidth: "800px" }}>
        <Header />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          <Link
            style={{ textDecoration: "none", margin: 0 }}
            to={props.lang === "ro" ? "/" : "/en/"}
          >
            <Chip
              style={{
                backgroundColor: "Transparent",
                color: "#444",
                marginBottom: 10,
                marginTop: 0,
                marginRight: 6,
                // border: 0,
                paddingLeft: 2,
                paddingRight: 2,
                border: "1px solid #cbcbcb",
              }}
              label={
                <strong>
                  {props.lang === "ro" ? "pagina principală" : "main page"}
                </strong>
              }
              icon={<ChevronLeft style={{ color: "#444" }} />}
            />
          </Link>
        </div>
        {/* <Divider style={{ marginBottom: 30, marginTop: 30 }} /> */}
        <Container
          style={{
            maxWidth: "800px",
            textAlign: "left",
            padding: 0,
            marginTop: 20,
            wrap: "wrap",
          }}
        >
          {/* <Divider style={{ marginBottom: 30 }} /> */}

          <Typography style={{ marginBottom: 10 }} variant="h5">
            <strong>
              {props.lang === "ro"
                ? "Politica privind datele personale"
                : "Personal data policy"}
            </strong>
          </Typography>
          <Typography style={{ fontWeight: 400 }} variant="subtitle1">
            {props.lang === "ro"
              ? "Această aplicație este furnizată de Casa Jurnalistului, o comunitate de jurnaliști independenți. Această politică privind procesarea datelor personale se aplică tuturor utilizatorilor paginii de internet coronavirus.casajurnalistului.ro."
              : "This application is provided by Casa Jurnalistului, a community of independent journalists. This policy regarding the processing of personal data applies to all users of the coronavirus.casajurnalistului.ro website."}
          </Typography>
          <Typography
            style={{ fontWeight: 400, marginBottom: 20, marginTop: 20 }}
            variant="body1"
          >
            {props.lang === "ro"
              ? "1. Această aplicație nu colectează date personale."
              : "1. This application does not collect personal data."}
          </Typography>
          <Typography
            style={{ fontWeight: 400, marginBottom: 20, marginTop: 20 }}
            variant="body1"
          >
            {props.lang === "ro" ? (
              <span>
                2. Folosim cookies pentru a colecta informații despre utilizarea
                aplicației. Aceste cookies sunt livrate de către un furnizor
                terț (Google, prin serviciul Google Analytics). Informațiile
                colectate includ: ora accesării aplicației, paginile vizionate,
                butoanele apăsate, timpul petrecut pe site, situarea geografică
                aproximativă a utilizatorului și alte informații. Puteți găsi
                mai multe informații în{" "}
                <a href="https://policies.google.com/privacy?hl=ro">
                  pagina dedicată oferită de Google
                </a>
                . Toate informațiile colectate sunt anonime.
              </span>
            ) : (
              <span>
                2. We use cookies to collect information about the use of the
                application. These cookies are delivered by a third-party
                provider (Google, through the Google Analytics service). The
                information collected includes the time of accessing the
                application, the pages viewed, the buttons pressed, the time
                spent on the site, the approximate geographical location of the
                user, and other information. You can find more information on
                the{" "}
                <a href="https://policies.google.com/privacy?hl=ro">
                  dedicated page provided by Google
                </a>
                . The collected information is anonymous.
              </span>
            )}
          </Typography>
          <Typography
            style={{ fontWeight: 400, marginBottom: 20, marginTop: 20 }}
            variant="body1"
          >
            {props.lang === "ro"
              ? "3. Utilizarea acestei aplicații nu este condiționată de folosirea cookies, pagina poate fi accesată fără nici o degradare cu cookies dezactivate."
              : "3. The use of this application is not conditioned by the use of cookies. All page can be accessed without any degradation with cookies disabled."}
          </Typography>
        </Container>
      </Container>

      <Container style={{ maxWidth: 1000, textAlign: "left" }}>
        {/* <Divider style={{ marginTop: 0, marginBottom: 30 }} />
        <Typography
          style={{ marginTop: 20, color: "grey" }}
          variant="subtitle2"
        >
          <strong>Surse:</strong>
        </Typography>
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="https://instnsp.maps.arcgis.com/apps/opsdashboard/index.html#/5eced796595b4ee585bcdba03e30c127"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          1. Institutul Național de Sănătate Publică din România
        </Typography>
         */}
        <Divider style={{ marginBottom: 30, marginTop: 30 }} />

        <div style={{ textAlign: "center", marginBottom: 40 }}>
          <Typography variant="subtitle2">Tech by</Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://addictad.ro"
          >
            <img
              style={{ height: 16, marginTop: 5 }}
              src="/images/logo-400px.png"
              alt=""
            />
          </a>
        </div>
      </Container>
    </div>
  );
}
