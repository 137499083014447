import Divider from "@material-ui/core/Divider";
import React from "react";

export default function Header() {
  return (
    <div>
      {/* <Divider style={{ marginBottom: 20, marginTop: 10 }} /> */}
      <a
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 20,
          marginTop: 20,
        }}
        href="#"
        onClick={() => window.location.reload()}
      >
        <img
          style={{ maxWidth: "80%", alignSelf: "center" }}
          src="/images/Monitorizare-Coronavirus.png"
          alt=""
        />
        <img
          style={{ maxHeight: "60px", alignSelf: "center" }}
          src="/images/hortensia-2210.png"
          alt=""
        />
      </a>

      <Divider style={{ marginBottom: 40, marginTop: 20 }} />
    </div>
  );
}
