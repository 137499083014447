import moment from "moment";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import vaccin_data from "../../data/vaccin.json";

vaccin_data.forEach(
  (itm) => (itm.dataOk = moment(itm.data, "MM/DD/YYYY").format("DD/MM 'YY"))
);

export default function ChartBarTeste(props) {
  return (
    <div
      style={{
        marginLeft: 0,
        height: window.innerWidth > 500 ? 350 : 350,
        width: "100%",
        display: "flex",
        justifyContent: "left",
      }}
    >
      <ResponsiveContainer
        // style={{ marginLeft: -20 }}
        width="100%"
        height="100%"
      >
        <AreaChart
          isAnimationActive={false}
          width={500}
          height={350}
          data={props.data ? props.data : []}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={{ fontSize: 12 }} dataKey="dataOk" />
          <YAxis
            tick={{ fontSize: 12 }}
            tickFormatter={(tick) =>
              Intl.NumberFormat().format(tick.toFixed(1))
            }
          />
          <Tooltip
            isAnimationActive={false}
            formatter={(lbl) => {
              return Intl.NumberFormat().format(lbl);
            }}
          />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="pfizer_1"
            stackId="1"
            stroke="#16a085"
            fill="#16a085"
            name="Pfizer (1)"
          />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="pfizer_2"
            stackId="1"
            stroke="#1abc9c"
            fill="#1abc9c"
            name="Pfizer (2)"
          />

          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="moderna_1"
            stackId="1"
            stroke="#f39c12"
            fill="#f39c12"
            name="Moderna (1)"
          />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="moderna_2"
            stackId="1"
            stroke="#f1c40f"
            fill="#f1c40f"
            name="Moderna (2)"
          />

          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="azeneca_1"
            stackId="1"
            stroke="#8e44ad"
            fill="#8e44ad"
            name="Astra-Zeneca (1)"
          />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="azeneca_2"
            stackId="1"
            stroke="#9b59b6"
            fill="#9b59b6"
            name="Astra-Zeneca (2)"
          />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="johnson"
            stackId="1"
            stroke="#3498db"
            fill="#3498db"
            name="Johnson & Johnson"
          />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="pfizer_3"
            stackId="1"
            stroke="rgba(26,188,156,0.4)"
            fill="rgba(26,188,156,0.4)"
            name="Pfizer (3)"
          />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="moderna_3"
            stackId="1"
            stroke="rgba(241,196,15,0.4)"
            fill="rgba(241,196,15,0.4)"
            name="Moderna (3)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
