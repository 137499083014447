import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function EuroBarVac(props) {
  const [localData, setLocalData] = useState(props.data);
  useEffect(() => {
    if (props.data) {
      let coll = [];
      props.data.forEach((itm) => {
        itm.week_date = moment(itm.week.replace("W", ""), "YYYY-W").format(
          "DD/MM 'YY"
        );
        coll.push(itm);
      });
      setLocalData(coll);
    }
  }, [props]);

  return (
    <div
      style={{
        marginLeft: 0,
        height: window.innerWidth > 500 ? 300 : 300,
        display: "flex",
        justifyContent: "left",
      }}
    >
      {props.data && props.data.length > 0 ? (
        <ResponsiveContainer
          // style={{ marginLeft: -40 }}
          width="100%"
          height="100%"
        >
          <AreaChart
            width={500}
            height={400}
            data={localData.sort((a, b) => (a.week > b.week ? 1 : -1))}
            margin={{
              top: 10,
              right: 0,
              left: 20,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="week_date" />
            <YAxis
              tickFormatter={(tick) =>
                Intl.NumberFormat().format(tick.toFixed(1))
              }
            />
            <Tooltip
              isAnimationActive={false}
              formatter={(lbl) => {
                return Intl.NumberFormat().format(lbl);
              }}
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="pfizer_1"
              stackId="1"
              stroke="#16a085"
              fill="#16a085"
              name="Pfizer (1)"
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="pfizer_2"
              stackId="1"
              stroke="#1abc9c"
              fill="#1abc9c"
              name="Pfizer (2)"
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="moderna_1"
              stackId="1"
              stroke="#f39c12"
              fill="#f39c12"
              name="Moderna (1)"
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="moderna_2"
              stackId="1"
              stroke="#f1c40f"
              fill="#f1c40f"
              name="Moderna (2)"
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="azeneca_1"
              stackId="1"
              stroke="#8e44ad"
              fill="#8e44ad"
              name="Astra-Zeneca (1)"
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="azeneca_2"
              stackId="1"
              stroke="#9b59b6"
              fill="#9b59b6"
              name="Astra-Zeneca (2)"
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="johnson"
              stackId="1"
              stroke="#3498db"
              fill="#3498db"
              name="Johnson & Johnson"
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="sputnik_1"
              stackId="1"
              stroke="#c0392b"
              fill="#c0392b"
              name="Sputnik (1)"
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="sputnik_2"
              stackId="1"
              stroke="#e74c3c"
              fill="#e74c3c"
              name="Sputnik (2)"
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="beijing_1"
              stackId="1"
              stroke="#22a6b3"
              fill="#22a6b3"
              name="Beijing CNBG (1)"
            />
            <Area
              isAnimationActive={false}
              type="monotone"
              dataKey="beijing_2"
              stackId="1"
              stroke="#7ed6df"
              fill="#7ed6df"
              name="Beijing CNBG (2)"
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : null}
    </div>
  );
}
