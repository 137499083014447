import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { request } from "graphql-request";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./App.css";
import { getAnd, oneWord } from "./gql/altQueries";
import { updateTime } from "./gql/queries";
import jud from "./sources/judNames.json";

export default function SurseOficiale() {
  const [currentArticles, setCurrentArticles] = useState([]);
  const [updated, setUpdated] = useState("indisponibil");
  const [queryOne, setQueryOne] = React.useState("");
  const [queryTwo, setQueryTwo] = React.useState("");

  useEffect(() => {
    request("https://www.add-dev.me/v1/graphql", updateTime, {
      sectiune: "cazuri",
    }).then(async (data) => {
      setUpdated(await data.updated[0].updated);
    });
  }, []);

  const handleGet = () => {
    queryOne !== "" && queryTwo !== ""
      ? request("https://www.add-dev.me/v1/graphql", getAnd, {
          time: moment().subtract(1, "days").format("X"),
          wone: "%" + escape(queryOne) + "%",
          wtwo: "%" + escape(queryTwo) + "%",
          spacer: 300,
        }).then((data) => {
          setCurrentArticles(data.articles);
          console.log(data);
        })
      : request("https://www.add-dev.me/v1/graphql", oneWord, {
          time: moment().subtract(1, "days").format("X"),
          wone: "%" + escape(queryOne) + "%",
          spacer: 300,
        }).then((data) => {
          setCurrentArticles(data.articles);
          console.log(data);
        });
  };

  console.log(updated);
  console.log(moment(updated, "hh:mm - DD/MM/yyyy").format("X"));
  console.log(currentArticles);

  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <Container style={{ maxWidth: "800px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          <img
            style={{ maxWidth: "80%", alignSelf: "center" }}
            src="/images/Monitorizare-Coronavirus.png"
            alt=""
          />
          <img
            style={{ maxHeight: "60px", alignSelf: "center" }}
            src="/images/hortensia-2210.png"
            alt=""
            onClick={() => window.location.reload()}
          />
        </div>
        <Divider style={{ marginBottom: 30, marginTop: 20 }} />

        {/* <ChipMenu active={"surse"} /> */}
      </Container>

      <Container style={{ maxWidth: 1000, textAlign: "left" }}>
        {/* <Divider style={{ marginBottom: 40, marginTop: 30 }} /> */}
        <Typography style={{ marginBottom: 10 }} variant="h5">
          <strong>Search</strong>{" "}
        </Typography>

        <Typography style={{ marginBottom: 30 }} variant="subtitle1">
          Caută în atributul contentSnippet până la două cuvinte cu operator
          AND:
          <ul>
            <li>caută în toate articolele tagged corona</li>
            <li>câmpul q1 obligatoriu</li>
            <li>merge bine cu word roots</li>
            <li>diacriticele contează</li>
          </ul>
        </Typography>

        <TextField
          id="q1"
          label="q1"
          style={{ marginRight: 10, marginBottom: 10 }}
          color="secondary"
          value={queryOne}
          onChange={(event) => setQueryOne(event.target.value.toLowerCase())}
          variant="outlined"
        />

        <TextField
          id="q2"
          label="q2"
          style={{ marginRight: 10, marginBottom: 10 }}
          color="secondary"
          value={queryTwo}
          onChange={(event) => setQueryTwo(event.target.value.toLowerCase())}
          variant="outlined"
        />
        <br />
        <Button
          onClick={handleGet}
          variant="contained"
          color="secondary"
          size="large"
        >
          Caută
        </Button>

        <Divider style={{ marginBottom: 30, marginTop: 20 }} />
        <Typography
          style={{ fontWeight: 700, marginBottom: 20 }}
          variant="subtitle1"
        >
          Rezultate: {currentArticles.length}
        </Typography>
        {currentArticles &&
          currentArticles.map((art) => (
            <Card
              key={art.isoDate}
              style={{
                marginBottom: 20,
                padding: 10,
                borderTop: "4px solid Crimson",
                backgroundColor: "rgb(248, 248, 248)",
              }}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={art.link}
                style={{ color: "#333", textDecoration: "none" }}
              >
                <CardContent>
                  <Typography variant="subtitle2">
                    <strong>{jud[art.judet]}</strong> |{" "}
                    {moment.unix(art.isoDate).format("HH:mm | DD MMM")} |{" "}
                    <u>
                      {art.link
                        .replace("https://", "")
                        .replace("http://", "")
                        .slice(
                          0,
                          art.link
                            .replace("https://", "")
                            .replace("http://", "")
                            .indexOf("/")
                        )}
                    </u>
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontSize: 18, marginTop: 10 }}
                  >
                    <strong>{unescape(art.title)}</strong>
                  </Typography>
                </CardContent>
              </a>
            </Card>
          ))}
        <div style={{ textAlign: "center", marginBottom: 40 }}>
          <Typography variant="subtitle2">Tech by</Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://addictad.ro"
          >
            <img
              style={{ height: 16, marginTop: 5 }}
              src="/images/logo-400px.png"
              alt=""
            />
          </a>
        </div>
      </Container>
    </div>
  );
}
