import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
const useStyles = makeStyles({
  legendItem: {
    marginRight: window.innerWidth > 600 ? "10px" : "10px",
  },
  legendHolder: {
    justifyContent: window.innerWidth > 600 ? "center" : "start",
  },
});

export default function LegendMy(props) {
  const classes = useStyles();

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",

          marginBottom: 10,
          marginTop: 0,
          flexWrap: "wrap",
        }}
        className={classes.legendHolder}
      >
        {props.content.map((item) => (
          <div
            // key={item.color}
            style={{
              display: "flex",

              marginRight: 10,
              marginBottom: 20,
            }}
          >
            <div
              className={classes.legendItem}
              style={{
                height: 20,
                width: 20,
                borderRadius: 4,
                textAlign: "center",
                backgroundColor: item.color,
              }}
            ></div>
            <Typography
              style={{ fontSize: 14, marginTop: 0, color: "grey" }}
              variant="body2"
            >
              {item.name}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
}
