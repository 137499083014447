import { scaleLog, scaleQuantize } from "d3-scale";
import React, { useEffect, useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import "../mapstyles.css";
import tariList from "../sources/tari.json";

const geoUrl = "/countries50m.json";

export default function EuroMapVac(props) {
  const [localState, setLocalState] = useState(props.data);

  useEffect(() => {
    setLocalState(props.data.filter((itm) => itm.week === props.data[0].week));
  }, [props]);

  const logScale = scaleLog().domain([10, 100]).range([1, 1000]);
  const colorScale = scaleQuantize().domain([1, 1000]).range(
    [
      "#b2182b",
      "#d6604d",
      "#f4a582",
      "#fddbc7",
      "#d1e5f0",
      "#92c5de",
      "#4393c3",
      "#2166ac",
    ]
    // .reverse()
  );

  return (
    <div>
      <ComposableMap
        data-tip=""
        projection="geoOrthographic"
        width={600}
        height={600}
        projectionConfig={{
          rotate: [-12, -53, -4],
          scale: 960,
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const cur =
                localState &&
                localState.filter((el) => el.country === geo.properties.name)
                  .length > 0
                  ? localState.filter(
                      (el) => el.country === geo.properties.name
                    )[0][!props.mapSwitch ? "pc_vac_2" : "pc_vac_1"]
                  : 0;
              return (
                <Geography
                  onClick={() => props.setClicked(geo.properties.name)}
                  onMouseEnter={() => {
                    const name = geo.properties.name;
                    // console.log(name, cur)
                    window.innerWidth < 500 &&
                      props.setClicked(geo.properties.name);
                    props.setTooltipContent(
                      `${
                        props.lang === "ro"
                          ? tariList.find((itm) => itm.en === name).ro
                          : name
                      } - ${new Intl.NumberFormat("ro-RO").format(
                        cur.toFixed(2)
                      )}`
                    );
                  }}
                  onMouseLeave={() => {
                    props.setTooltipContent("");
                  }}
                  key={geo.rsmKey}
                  geography={geo}
                  fill={cur !== 0 ? colorScale(logScale(cur)) : "#cbcbcb"}
                  stroke="#FFFFFF"
                  strokeWidth={1}
                  style={{
                    hover: {
                      opacity: 0.8,
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
}
