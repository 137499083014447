import { scaleLog, scaleQuantize } from "d3-scale";
import React, { useEffect, useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import "../mapstyles.css";
import tariList from "../sources/tari.json";

const geoUrl = "/countries50m.json";

// console.log(legendColors);
export default function GeoMap(props) {
  // console.log(props.mapSwitch);
  const [localState, setLocalState] = useState(props.data);
  const [currentWeek, setCurrentWeek] = useState();

  useEffect(() => {
    let latestWeeks = Array.from(
      new Set(
        props.data.map((itm) => itm.year_week).sort((a, b) => (a > b ? -1 : 1))
      )
    );
    setCurrentWeek(latestWeeks[Math.abs(props.daySwitch)]);
    setLocalState(
      props.data.filter(
        (itm) => itm.year_week === latestWeeks[Math.abs(props.daySwitch)]
      )
    );
  }, [props]);
  // console.log(localState);

  const logScale = scaleLog()
    .domain(
      props.mapSwitch ? [props.minPm, props.maxPm] : [props.min, props.max]
    )
    .range([1, 1000]);
  const colorScale = scaleQuantize()
    .domain([1, 1000])
    .range(
      [
        "#b2182b",
        "#d6604d",
        "#f4a582",
        "#fddbc7",
        "#d1e5f0",
        "#92c5de",
        "#4393c3",
        "#2166ac",
      ].reverse()
    );

  let legendColors = [];
  colorScale.range().forEach((color) =>
    legendColors.push({
      color: color,
      low: colorScale.invertExtent(color)[0],
      high: colorScale.invertExtent(color)[1],
    })
  );
  // console.log(props.data);
  return (
    <div>
      <ComposableMap
        data-tip=""
        projection="geoOrthographic"
        projectionConfig={{
          rotate: [-20, -53, 8],
          scale: 900,
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const cur =
                localState &&
                localState.filter((el) => el.country === geo.properties.name)
                  .length > 0
                  ? localState.filter(
                      (el) => el.country === geo.properties.name
                    )[0][
                      !props.mapSwitch
                        ? props.indicator === "cases"
                          ? "weekly_count"
                          : "weekly_count_deaths"
                        : props.indicator === "cases"
                        ? "weekly"
                        : "weekly_deaths"
                    ]
                  : 0;
              return (
                <Geography
                  onClick={() => props.setClicked(geo.properties.name)}
                  onMouseEnter={() => {
                    const name = geo.properties.name;
                    // console.log(name, cur)
                    window.innerWidth < 500 &&
                      props.setClicked(geo.properties.name);
                    props.setTooltipContent(
                      `${
                        props.lang === "ro"
                          ? tariList.find((itm) => itm.en === name).ro
                          : name
                      } - ${new Intl.NumberFormat("ro-RO").format(
                        parseInt(cur.toFixed(2))
                      )}`
                    );
                  }}
                  onMouseLeave={() => {
                    props.setTooltipContent("");
                  }}
                  key={geo.rsmKey}
                  geography={geo}
                  fill={cur !== 0 ? colorScale(logScale(cur)) : "#cbcbcb"}
                  stroke="#FFFFFF"
                  strokeWidth={1}
                  style={{
                    hover: {
                      opacity: 0.8,
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </div>
  );
}
