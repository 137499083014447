import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Refresh from "@material-ui/icons/Refresh";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import "./App.css";
import ChipMenu from "./components/ChipMenu";
import Header from "./components/common/Header";
import EuroBar from "./components/EuroBar.js";
import EuroChart from "./components/EuroChart.js";
import EuroMap from "./components/EuroMap.js";
import GlobeMap from "./components/timeline/TimeGlobeMap";
import updateTime from "./data/updated.json";
import tariList from "./sources/tari.json";

moment.locale("ro");

let europeList = [
  "Turkey",
  "Albania",
  "Andorra",
  "Armenia",
  "Austria",
  "Azerbaijan",
  "Belarus",
  "Belgium",
  "Bosnia and Herzegovina",
  "Bulgaria",
  "Croatia",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "HOLY SEE VATICAN CITY STATE",
  "Hungary",
  "Iceland",
  "Ireland",
  "Italy",
  "Latvia",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Moldova",
  "Monaco",
  "Netherlands",
  "North Macedonia",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "San Marino",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
  "Ukraine",
  "United Kingdom",
  "Morocco",
  "Algeria",
  "Tunisia",
  "Egypt",
  "Israel",
  "Palestine",
  "Lebanon",
  "Iran",
  "Iraq",
  "Saudi Arabia",
  "Kuwait",
  "Jordan",
  "Syria",
  "Kazakhstan",
  "Turkmenistan",
  "Uzbekistan",
];

let tariEurope = [];
tariList.forEach(
  (item) => europeList.includes(item.en) && tariEurope.push(item)
);

const marksEng = [
  {
    value: 0,
    label: "0",
  },

  {
    value: -12,
    label: "-3",
  },

  {
    value: -24,
    label: "-6",
  },

  {
    value: -36,
    label: "-9",
  },
  {
    value: -48,
    label: "-12",
  },
  {
    value: -60,
    label: "-15",
  },
  {
    value: -72,
    label: "-18",
  },

  {
    value: -84,
    label: "-21",
  },
];

function InternationalMap(props) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const [ttip, setTtip] = useState("");

  const [clicked, setClicked] = useState("None");
  const [mapSwitch, setMapSwitch] = useState(false);
  const [daySwitch, setDaySwitch] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [selectedMap, setSelectedMap] = useState("europa");

  const [selectedChart, setSelectedChart] = useState("daily");

  const [allData, setAllData] = useState([]);

  const getData = async () => {
    await fetch(`/data/${props.file}.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        data.forEach(
          (itm) =>
            (itm.realDate = moment(itm.year_week, "YYYY_W").format("D/M/'YY"))
        );
        setAllData(data);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const handleSlider = (event, newValue) => {
    setDaySwitch(newValue);
  };

  useEffect(() => {
    clicked !== "None" &&
      ReactGA.event({
        category: "Europe",
        action: clicked,
      });

    setChartData([]);
    clicked === "None"
      ? setChartData(
          allData
            .filter(
              (item) =>
                item.country ===
                (selectedMap === "europa" ? "Europe (total)" : "World")
            )
            .sort((a, b) =>
              moment(a.DateRep, "DD-MM-YYYY") > moment(b.DateRep, "DD-MM-YYYY")
                ? 1
                : -1
            )
        )
      : setChartData(
          allData
            .filter((item) => item.country === clicked)
            .sort((a, b) =>
              moment(a.DateRep, "DD-MM-YYYY") > moment(b.DateRep, "DD-MM-YYYY")
                ? 1
                : -1
            )
        );
  }, [clicked, selectedMap, allData]);
  // console.log(cazuri);
  // console.log(cazuriActive);
  useEffect(() => {
    setClicked("None");
  }, [selectedMap]);

  const [currentWeek, setCurrentWeek] = useState();
  useEffect(() => {
    let latestWeeks = Array.from(
      new Set(
        allData.map((itm) => itm.year_week).sort((a, b) => (a > b ? -1 : 1))
      )
    );
    setCurrentWeek(latestWeeks[Math.abs(daySwitch)]);
  }, [daySwitch, allData]);

  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <Typography
        variant="body2"
        style={{
          fontSize: 12,
          backgroundColor: "#dd1b14",
          color: "white",
          padding: 4,
          width: "100%",
        }}
      >
        {props.lang === "ro"
          ? "Această aplicație folosește cookies pentru a colecta statistici de utilizare. Prin folosirea aplicației îți exprimi acordul cu "
          : "This application uses cookies to collect usage statistics. By using the application you accept "}
        <Link
          style={{ color: "white", margin: 0 }}
          to={props.lang === "ro" ? "/cookies/" : "/en/cookies/"}
        >
          {props.lang === "ro"
            ? "politica noastră privind datele personale"
            : "our personal data policy"}
        </Link>
        .
      </Typography>

      <Container style={{ maxWidth: "800px" }}>
        <Header />
        <ChipMenu active={props.actv} lang={props.lang} />

        <Container
          style={{
            maxWidth: "800px",
            textAlign: "left",
            padding: 0,
            marginTop: 20,
          }}
        >
          <Typography style={{ marginBottom: 10, marginTop: 30 }} variant="h5">
            <strong>
              {props.lang === "ro"
                ? props.titleText + " în lume"
                : props.titleText + " worldwide"}
            </strong>
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 20,
              marginTop: 5,
              color: "grey",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro" ? "Actualizat: " : "Updated: "}{" "}
            {updateTime.europa}
          </Typography>
          <Typography
            style={{ fontWeight: 700, marginTop: 20 }}
            variant="subtitle1"
          >
            {props.lang === "ro"
              ? "Notă: din data de 20 decembrie 2021, ECDC nu a mai distribuit date pentru state non-UE."
              : "Note: As of December 20, 2021, the ECDC has stopped providing data about non-EU countries."}
          </Typography>

          <Typography
            style={{ fontWeight: 700, marginTop: 10 }}
            variant="subtitle1"
          >
            {props.lang === "ro" ? "Alege o hartă:" : "Select a map:"}
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup
              style={{
                width: "auto",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                marginTop: 0,
              }}
              aria-label="gender"
              name="gender1"
              value={selectedMap}
              onChange={(event) => setSelectedMap(event.target.value)}
            >
              <FormControlLabel
                value="europa"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "Europa" : "Europe"}
                  </div>
                }
              />
              <FormControlLabel
                value="glob"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "tot globul" : "globe"}
                  </div>
                }
              />
            </RadioGroup>
          </FormControl>

          {selectedMap === "europa" && (
            <Typography
              style={{ fontWeight: 400, marginTop: 0, marginBottom: 20 }}
              variant="subtitle1"
            >
              {props.lang === "ro" ? (
                <span>
                  <strong>Harta curentă: </strong>numărul și distribuția{" "}
                  {props.typeText}lor noi de COVID-19 în Europa (date
                  săptămânale)
                </span>
              ) : (
                <span>
                  <strong>Current map: </strong>number and distribution of
                  weekly new COVID-19 {props.typeText} in Europe
                </span>
              )}
            </Typography>
          )}

          {selectedMap === "glob" && (
            <Typography
              style={{ fontWeight: 400, marginTop: 0, marginBottom: 20 }}
              variant="subtitle1"
            >
              {props.lang === "ro" ? (
                <span>
                  <strong>Harta curentă: </strong>numărul și distribuția{" "}
                  {props.typeText}lor noi de COVID-19 la nivel global (date
                  săptămânale)
                </span>
              ) : (
                <span>
                  <strong>Current map: </strong>number and distribution of
                  weekly new COVID-19 {props.typeText} worldwide.
                </span>
              )}
            </Typography>
          )}

          <div
            style={{
              width: window.innerWidth > 500 ? "100%" : "108%",
              marginLeft: window.innerWidth > 500 ? "0%" : "-4%",
            }}
          >
            {selectedMap === "europa" && (
              <Fragment>
                <EuroMap
                  min={props.eumin}
                  max={props.eumax}
                  minPm={props.euminPm}
                  maxPm={props.eumaxPm}
                  daySwitch={daySwitch}
                  setClicked={setClicked}
                  mapSwitch={mapSwitch}
                  data={allData}
                  setTooltipContent={setTtip}
                  lang={props.lang}
                  indicator={props.indicator}
                />
                <ReactTooltip>{ttip}</ReactTooltip>
              </Fragment>
            )}
            {selectedMap === "glob" && (
              <div style={{ width: "100%", marginTop: 10 }}>
                <GlobeMap
                  min={props.min}
                  max={props.max}
                  minPm={props.minPm}
                  maxPm={props.maxPm}
                  daySwitch={daySwitch}
                  mapSwitch={mapSwitch}
                  data={allData}
                  setClicked={setClicked}
                  setTooltipContent={setTtip}
                  lang={props.lang}
                  indicator={props.indicator}
                />
                <ReactTooltip>{ttip}</ReactTooltip>
              </div>
            )}
          </div>
        </Container>

        <div style={{ width: "100%", marginTop: 30 }}></div>
        <div style={{ marginTop: 10, marginBottom: 0 }}>
          <strong
            style={{
              padding: 4,
              paddingRight: 10,
              paddingLeft: 10,
              borderRadius: 4,
              backgroundColor: "Crimson",
              color: "white",
              marginTop: 10,
            }}
          >
            {moment(currentWeek, "YYYY_W").format("D/MM")} -{" "}
            {moment(currentWeek, "YYYY_W").add(7, "days").format("D/MM 'YY")}
          </strong>
        </div>
        <Typography
          style={{
            color: "#4F4F4F",
            lineHeight: "18px",
            marginBottom: 0,
            marginTop: 10,
          }}
          variant="subtitle2"
        >
          {props.lang === "ro"
            ? "- click pe orice țară pentru detalii -"
            : "- click any country for more details -"}
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={mapSwitch}
              onChange={() => setMapSwitch(!mapSwitch)}
              name="cpm"
              color="secondary"
            />
          }
          label={
            props.lang === "ro"
              ? props.typeText + " per milion de locuitori"
              : props.typeText + " per million inhabitants"
          }
          style={{ marginBottom: 0, marginTop: 0 }}
        />

        <Typography
          style={{ fontWeight: 400, marginTop: 20, textAlign: "left" }}
          variant="subtitle1"
        >
          {props.lang === "ro" ? (
            <span>Alege un moment de la începutul pandemiei: </span>
          ) : (
            <span>Select a moment since the start of the pandemic:</span>
          )}
        </Typography>

        <div
          style={{
            marginTop: 10,
            marginBottom: 0,
            paddingLeft: 10,
            paddingRight: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Slider
            value={daySwitch}
            onChange={handleSlider}
            track="inverted"
            defaultValue={0}
            aria-labelledby="discrete-slider"
            step={1}
            marks={marksEng}
            min={-90}
            max={0}
            color="secondary"
            style={{ width: "100%" }}
          />
        </div>

        <Divider style={{ marginBottom: 50, marginTop: 40 }} />

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "left",
            marginBottom: 0,
          }}
        >
          <FormControl style={{ marginBottom: 10, marginTop: 0 }}>
            <Select
              style={{
                fontSize: "1.5rem",
                fontWeight: 700,
                borderBottom: 0,
                marginRight: 10,
              }}
              labelId="jduet-label"
              disableUnderline
              color="secondary"
              id="jduet-select"
              value={clicked}
              onChange={(event) => setClicked(event.target.value)}
            >
              <MenuItem key="None" value="None">
                {props.lang === "ro" ? (
                  <span>
                    Tot {selectedMap === "europa" ? "continentul" : "globul"}{" "}
                  </span>
                ) : (
                  <span>
                    Entire {selectedMap === "europa" ? "continent" : "globe"}{" "}
                  </span>
                )}
              </MenuItem>
              {(selectedMap === "europa"
                ? tariEurope.sort((a, b) =>
                    props.lang === "ro"
                      ? a.ro < b.ro
                        ? -1
                        : 1
                      : a.en > b.en
                      ? 1
                      : -1
                  )
                : tariList.sort((a, b) =>
                    props.lang === "ro"
                      ? a.ro < b.ro
                        ? -1
                        : 1
                      : a.en > b.en
                      ? 1
                      : -1
                  )
              ).map((j) => (
                <MenuItem key={j.en} value={j.en}>
                  {props.lang === "ro" ? j.ro : j.en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {clicked !== "None" ? (
            <Chip
              onClick={() => setClicked("None")}
              style={{
                backgroundColor: "Crimson",
                color: "white",
                marginBottom: 10,
                marginTop: 0,
                marginLeft: 0,
                border: 0,
                paddingLeft: 2,
                paddingRight: 2,
              }}
              label={<strong>RESET</strong>}
              icon={<Refresh style={{ color: "white" }} />}
            />
          ) : (
            <Chip
              style={{
                color: "#555",
                marginBottom: 10,
                marginTop: 0,
                marginLeft: 0,
                border: 0,
                paddingLeft: 2,
                paddingRight: 2,
              }}
              label={
                <strong>
                  {props.lang === "ro" ? "ALEGE ȚARA" : "SELECT COUNTRY"}
                </strong>
              }
            />
          )}
        </div>
        <Typography
          style={{
            fontWeight: 400,
            marginBottom: 20,
            marginTop: 0,
            color: "grey",
            textAlign: "left",
          }}
          variant="subtitle2"
        >
          {props.lang === "ro" ? "Actualizat: " : "Updated: "}
          {updateTime.europa}
        </Typography>

        <div style={{ textAlign: "left" }}>
          {clicked !== "None" && (
            <Typography style={{ marginTop: 10 }} variant="subtitle1">
              <strong>
                {props.lang === "ro" ? "Populație:" : "Population:"}
              </strong>{" "}
              {clicked !== "None" &&
                tariList.find((itm) => itm.en === clicked) &&
                Intl.NumberFormat().format(
                  tariList.find((itm) => itm.en === clicked).pop_mil * 1000000
                )}
            </Typography>
          )}
          <Typography
            style={{ fontWeight: 700, marginTop: 10 }}
            variant="subtitle1"
          >
            {props.lang === "ro" ? "Alege un grafic:" : "Select a chart:"}
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              style={{
                width: "auto",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                marginTop: 0,
              }}
              name="gender1"
              value={selectedChart}
              onChange={(event) => setSelectedChart(event.target.value)}
            >
              <FormControlLabel
                value="total"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "totaluri" : "totals"}
                  </div>
                }
              />
              <FormControlLabel
                value="daily"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "cifre săptămânale" : "weekly data"}
                  </div>
                }
              />
            </RadioGroup>
          </FormControl>
          {selectedChart === "total" ? (
            <Fragment>
              <Typography
                style={{
                  fontWeight: 400,
                  marginBottom: 30,
                  marginTop: 10,
                  textAlign: "left",
                }}
                variant="subtitle1"
              >
                {props.lang === "ro" ? (
                  <span>
                    <strong>Grafic: </strong>
                    cazuri și decese (date totale); valorile de pe axele
                    verticale din sunt exprimate în mii;
                  </span>
                ) : (
                  <span>
                    <strong>Chart: </strong>
                    cases and deaths (total data); Y axis values are expressed
                    in thousands;
                  </span>
                )}
              </Typography>

              {chartData && chartData.length > 0 ? (
                <EuroChart data={chartData} lang={props.lang} />
              ) : (
                <strong>
                  {props.lang === "ro"
                    ? "informații indisponibile"
                    : "no data available"}
                </strong>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <Typography
                style={{
                  fontWeight: 400,
                  marginBottom: 30,
                  marginTop: 10,
                  textAlign: "left",
                }}
                variant="subtitle1"
              >
                {props.lang === "ro" ? (
                  <span>
                    <strong>Grafic: </strong>
                    cazuri și decese (date săptămânale); valorile de pe axele
                    verticale din sunt exprimate în mii;
                  </span>
                ) : (
                  <span>
                    <strong>Chart: </strong>
                    cases & deaths (weekly data); Y axis values are expressed in
                    thousands;
                  </span>
                )}
              </Typography>
              {chartData && chartData.length > 0 ? (
                <EuroBar
                  updated={updateTime.europa}
                  data={chartData}
                  lang={props.lang}
                  typeText={props.typeText}
                />
              ) : (
                <strong>
                  {props.lang === "ro"
                    ? "informații indisponibile"
                    : "no data available"}
                </strong>
              )}
            </Fragment>
          )}
          {clicked === "None" && selectedMap === "europa" && (
            <Typography
              style={{ marginTop: 30, color: "grey" }}
              variant="subtitle2"
            >
              {props.lang === "ro" ? (
                <span>
                  <strong>Totalul este compus din datele pentru:</strong>{" "}
                  Albania, Andora, Armenia, Austria, Azerbaidjan, Belarus,
                  Belgia, Bosnia și Herțegovina, Bulgaria, Cehia, Croația,
                  Danemarca, Elveția, Estonia, Finlanda, Franța, Georgia,
                  Germania, Grecia, Irlanda, Islanda, Italia, Letonia,
                  Liechtenstein, Lituania, Luxemburg, Macedonia, Malta, Marea
                  Britanie, Moldova, Monaco, Norvegia, Olanda, Polonia,
                  Portugalia, România, Rusia, San Marino, Serbia, Slovacia,
                  Slovenia, Spania, Suedia, Turcia, Ucraina, Ungaria, Vatican
                </span>
              ) : (
                <span>
                  <strong>The total consists of data for: </strong> Albania,
                  Andorra, Armenia, Austria, Azerbaijan, Belarus, Belgium,
                  Bosnia and Herzegovina, Bulgaria, Czech Republic, Croatia,
                  Denmark, Switzerland, Estonia, Finland, France, Georgia,
                  Germany, Greece, Ireland, Iceland , Italy, Latvia,
                  Liechtenstein, Lithuania, Luxembourg, Macedonia, Malta, Great
                  Britain, Moldova, Monaco, Norway, Netherlands, Poland,
                  Portugal, Romania, Russia, San Marino, Serbia, Slovakia,
                  Slovenia, Spain, Sweden, Turkey, Ukraine, Hungary, Vatican
                </span>
              )}
            </Typography>
          )}
        </div>
      </Container>

      <Container style={{ maxWidth: 800, textAlign: "left" }}>
        <Divider style={{ marginTop: 30, marginBottom: 30 }} />

        <Typography
          style={{ marginTop: 20, marginBottom: 10, color: "grey" }}
          variant="subtitle2"
        >
          <strong>{props.lang === "ro" ? "Surse:" : "Sources:"}</strong>
        </Typography>
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="https://www.ecdc.europa.eu/en/novel-coronavirus-china"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          1. European Centre for Disease Prevention and Control
        </Typography>

        <Divider style={{ marginBottom: 30, marginTop: 30 }} />

        <div style={{ textAlign: "center", marginBottom: 40 }}>
          <Typography variant="subtitle2">Tech by</Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://addictad.ro"
          >
            <img
              style={{ height: 16, marginTop: 5 }}
              src="/images/logo-400px.png"
              alt=""
            />
          </a>
        </div>
      </Container>
    </div>
  );
}

export default InternationalMap;
