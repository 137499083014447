import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Refresh from "@material-ui/icons/Refresh";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
// import {Helmet} from "react-helmet";
import "./App.css";
import CaseMapCz from "./components/czech/CaseMapCz";
import DeadMapCz from "./components/czech/DeadMapCz";
import DailyChartCz from "./components/czech/DailyChartCz";
import DailyChartDeathsCz from "./components/czech/DailyChartDeathsCz";
import DeadMapRo from "./components/romania/DeadMapRo";
import casesCz from "./data/cz_cases_per_region_2021_01_08.json";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import updateTime from "./data/updated.json";
// import JustArticles from "./components/presa/JustArticles.js";
// import CuvinteCheie from "./CuvinteCheie";
// import SanctiuniTeste from "./components/romania/SanctiuniTeste";
// import NtrendComp from "./NtrendComp";
import bohemiaNuts from "./sources/central_bohemia.json";
import czNuts from "./sources/cz_nuts_3.json";
import judNames from "./sources/judNames.json";
import timelineMarks from "./sources/timelineMarks.json";
import timelineMarksEn from "./sources/timelineMarksEn.json";

// ReactGA.initialize("UA-1029715-12");
// ReactGA.pageview(window.location.pathname);

const useStyles = makeStyles({
  infoBubble: {
    display: "inline-block",
    marginTop: 4,
    marginBottom: 4,
    marginRight: 10,
    width: window.innerWidth > 500 ? "23%" : "47%",
    fontSize: 16,
    paddingTop: 2,
    paddingBottom: 2,
    opacity: 0.9,
    textAlign: "left",
  },
  redBottom: {
    borderBottom: "4px solid Crimson",
  },
  yellowBottom: {
    borderBottom: "4px solid #FFC107",
  },
  greenBottom: {
    borderBottom: "4px solid rgb(86, 159, 57)",
  },
  blueBottom: {
    borderBottom: "4px solid rgb(60, 153, 220)",
  },
  blackBottom: {
    borderBottom: "4px solid #333",
  },
});

function RoMap(props) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const classes = useStyles();
  // per judet: cazuri_toal, decese_total, judet, lat, long
  const [cazuri, setCazuri] = useState([]);
  const [cazuriAzi, setCazuriAzi] = useState([]);
  const [roLineData, setRoLineData] = useState([]);
  const [allData, setAllData] = useState([]);

  // toate cazurile de toate tipurile
  const [allCases, setAllCases] = useState([]);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [dailyTotal, setDailyTotal] = useState(0);
  const [currentTotalDead, setCurrentTotalDead] = useState(0);
  const [dailyTotalDead, setDailyTotalDead] = useState(0);
  const [currentTotalVindecati, setCurrentTotalVindecati] = useState(0);
  const [dailyTotalVindecati, setDailyTotalVindecati] = useState(0);
  const [updated, setUpdated] = useState("indisponibil");
  const [selectedMap, setSelectedMap] = useState("cazuri");
  const [selectedChart, setSelectedChart] = useState("daily");
  const [clicked, setClicked] = useState("None");
  const [mapSwitch, setMapSwitch] = useState(false);
  const [daySwitch, setDaySwitch] = useState(0);

  // ----------------------------------------------------------------

  // const [time, setTime] = useState(new Date().toLocaleTimeString());
  //   useEffect(() => {
  //     const timeout = setTimeout(() => {
  //       const date = new Date()
  //       setTime(date.toLocaleTimeString());
  //       setDaySwitch(daySwitch+1)
  //     }, 1000);
  //     return () => {
  //       clearTimeout(timeout);
  //     }
  //   }, [time]);

  // ----------------------------------------------------------------

  // on page load -> cazuri, allCases, updated, updatedTeste, teste
  // useEffect(() => {
  //   let getData = async () => {
  //     let localCopy = dailyCalc.sort((a, b) =>
  //       a.data_unix > b.data_unix ? -1 : 1
  //     );

  //     let firstTenDays = [];
  //     let caseNoTotal = [];

  //     localCopy
  //       // .filter((itm) => itm.judet !== "necomunicat")
  //       .forEach((itm) => {
  //         if (itm.judet === "ro-b") {
  //           itm.cazuriAzi =
  //             localCopy.filter(
  //               (r) => r.judet === "ro-if" && r.data === itm.data
  //             )[0].cazuriAzi + itm.cazuriAzi;
  //           itm.cazuriTotal =
  //             localCopy.filter(
  //               (r) => r.judet === "ro-if" && r.data === itm.data
  //             )[0].cazuriTotal + itm.cazuriTotal;
  //           itm.deceseAzi =
  //             localCopy.filter(
  //               (r) => r.judet === "ro-if" && r.data === itm.data
  //             )[0].deceseAzi + itm.deceseAzi;
  //           itm.deceseTotal =
  //             localCopy.filter(
  //               (r) => r.judet === "ro-if" && r.data === itm.data
  //             )[0].deceseTotal + itm.deceseTotal;
  //         }
  //         firstTenDays.push(itm);
  //         itm.judet === "total" && caseNoTotal.push(itm);
  //       });

  //     caseNoTotal = caseNoTotal.sort((a, b) =>
  //       a.data_unix > b.data_unix ? -1 : 1
  //     );

  //     // caseNoTotal.forEach(
  //     //   // (day) => (day.data = day.data.replace("/2020", "").replace("/2021", ""))
  //     //   (day) => (day.data = day.data)
  //     // );
  //     setAllData(firstTenDays);
  //     // setRoLineData(caseNoTotal);
  //     setCurrentTotal(caseNoTotal[caseNoTotal.length - 1].cazuriTotal);
  //     setCurrentTotalDead(caseNoTotal[caseNoTotal.length - 1].deceseTotal);
  //     setCurrentTotalVindecati(caseNoTotal[caseNoTotal.length - 1].vindTotal);
  //     setDailyTotal(caseNoTotal[caseNoTotal.length - 1].cazuriAzi);
  //     setDailyTotalDead(caseNoTotal[caseNoTotal.length - 1].deceseAzi);
  //     setDailyTotalVindecati(caseNoTotal[caseNoTotal.length - 1].vindAzi);

  //     // setAllCases(
  //     //   dailyJson.sort((a, b) => {
  //     //     if (moment(a.data, "MM/DD/YYYY") > moment(b.data, "MM/DD/YYYY")) {
  //     //       return -1;
  //     //     } else {
  //     //       return 1;
  //     //     }
  //     //   })
  //     // );
  //   };

  //   getData();
  // }, []);

  // console.log(casesCz[casesCz.length - 1 - daySwitch]);
  useEffect(() => {
    let caseNoPerJud = [];
    if (casesCz) {
      let todayCases = casesCz[casesCz.length - 1 + daySwitch];
      let todayDeaths = casesCz[casesCz.length - 1 + daySwitch].deaths;
      czNuts.forEach((nut) => {
        let obj = {
          lat: czNuts.find((i) => i === nut).lat,
          long: czNuts.find((i) => i === nut).long,
          judet: nut.code,
          cazuri_azi: mapSwitch
            ? (todayCases[nut.code] / nut.pop_100k).toFixed(1)
            : todayCases[nut.code],
          decese_azi: mapSwitch
            ? (todayDeaths[nut.code] / nut.pop_100k).toFixed(1)
            : todayDeaths[nut.code],
        };
        caseNoPerJud.push(obj);
      });
      // bohemiaNuts.forEach((nut) => {
      //   let obj = {
      //     lat: bohemiaNuts.find((i) => i === nut).lat,
      //     long: bohemiaNuts.find((i) => i === nut).long,
      //     judet: nut.code,
      //     cazuri_azi: mapSwitch
      //       ? (todayCases[nut.code] / nut.pop_100k).toFixed(1)
      //       : todayCases[nut.code],
      //   };
      //   caseNoPerJud.push(obj);
      // });

      if (clicked !== "None") {
        let coll = [];
        casesCz.forEach((cs) => {
          console.log(cs);
          let obj = {
            data: cs.date,
            cazuriAzi: cs[clicked],
            deceseAzi: cs.deaths[clicked],
          };
          coll.push(obj);
        });
        setRoLineData(coll);
      } else {
        let coll = [];
        casesCz.forEach((cs) => {
          let obj = {
            data: cs.date,
            cazuriAzi: cs.total,
            deceseAzi: cs.deaths.total,
          };
          coll.push(obj);
        });
        setRoLineData(coll);
      }
    }

    setCazuri(caseNoPerJud);
  }, [mapSwitch, allData, daySwitch, clicked]);

  const handleSlider = (event, newValue) => {
    setDaySwitch(newValue);
  };
  // console.log("CZ DATA", casesCz);
  // console.log("CZ NUTS", czNuts);
  // console.log("CAZURI", cazuri);
  // console.log(daySwitch);
  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <Typography
        variant="body2"
        style={{
          fontSize: 12,
          backgroundColor: "#dd1b14",
          color: "white",
          padding: 4,
          width: "100%",
        }}
      >
        {props.lang === "ro"
          ? "Această aplicație folosește cookies pentru a colecta statistici de utilizare. Prin folosirea aplicației îți exprimi acordul cu "
          : "This application uses cookies to collect usage statistics. By using the application you accept "}
        <Link
          style={{ color: "white", margin: 0 }}
          to={props.lang === "ro" ? "/cookies/" : "/en/cookies/"}
        >
          {props.lang === "ro"
            ? "politica noastră privind datele personale"
            : "our personal data policy"}
        </Link>
        .
      </Typography>
      <Container style={{ maxWidth: "800px" }}>
        {/* <Header />
        <ChipMenu active={"ro"} lang={props.lang} /> */}

        <Container
          style={{
            textAlign: "left",
            padding: 0,
            marginTop: 20,
          }}
        >
          <Typography style={{ marginBottom: 10, marginTop: 30 }} variant="h5">
            <strong>
              {props.lang === "ro"
                ? "Epidemia în Cehia"
                : "The epidemic in the Czech Republic"}
            </strong>
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 20,
              marginTop: 5,
              color: "grey",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro" ? "Actualizat: " : "Updated: "}{" "}
            {updateTime.main}
          </Typography>

          <Typography
            style={{ fontWeight: 700, marginTop: 20 }}
            variant="subtitle1"
          >
            {props.lang === "ro" ? "Alege o hartă:" : "Select a map:"}
          </Typography>
          {/* 
          <FormControl component="fieldset">
            <RadioGroup
              style={{
                width: "auto",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                marginTop: 0,
              }}
              aria-label="selected-map"
              name="selectedmap"
              value={selectedMap}
              onChange={(event) => setSelectedMap(event.target.value)}
            >
              <FormControlLabel
                value="cazuri"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "cazuri" : "cases"}
                  </div>
                }
              />
              <FormControlLabel
                value="decese"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "decese" : "deaths"}
                  </div>
                }
              />
            </RadioGroup>
          </FormControl> */}

          <div>
            {selectedMap === "cazuri" && (
              <Typography
                style={{ fontWeight: 400, marginTop: 0, marginBottom: 10 }}
                variant="subtitle1"
              >
                {/* {props.lang === "ro" ? (
                  <strong>Harta curentă: </strong>
                ) : (
                  <strong>Current map: </strong>
                )} */}
                {props.lang === "ro"
                  ? "Numărul și distribuția cazurilor de COVID-19 în Republica Cehă."
                  : "Number and distribution of new COVID-19 cases in the Czech Republic:"}
              </Typography>
            )}
          </div>

          <div>
            {selectedMap === "decese" && (
              <Typography
                style={{ fontWeight: 400, marginTop: 0, marginBottom: 10 }}
                variant="subtitle1"
              >
                {props.lang === "ro" ? (
                  <strong>Harta curentă: </strong>
                ) : (
                  <strong>Current map: </strong>
                )}
                {props.lang === "ro"
                  ? "numărul și distribuția cazurilor de COVID-19 din ultima zi în România."
                  : "number and distribution of COVID-19 cases from the most recent day in Romania."}
              </Typography>
            )}
          </div>

          <Typography
            style={{ fontWeight: 400, marginTop: 0, marginBottom: 20 }}
            variant="subtitle1"
          ></Typography>

          <div style={{ width: "108%", marginLeft: "-4%" }}>
            <div>
              {selectedMap === "cazuri" && (
                <CaseMapCz
                  setClicked={setClicked}
                  cazuri={cazuri}
                  mapSwitch={mapSwitch}
                  lang={props.lang}
                />
              )}
              {/* <CasesCentralBohemia
                setClicked={setClicked}
                cazuri={cazuri}
                mapSwitch={mapSwitch}
                lang={props.lang}
              /> */}
            </div>
            <div>
              {selectedMap === "decese" && (
                <DeadMapCz
                  setClicked={setClicked}
                  cazuri={cazuri}
                  mapSwitch={false}
                  lang={props.lang}
                />
              )}
            </div>

            <div
              style={{ marginTop: 10, marginBottom: 10, textAlign: "center" }}
            >
              {clicked !== "None" && (
                <strong
                  style={{
                    padding: 4,
                    paddingRight: 10,
                    paddingLeft: 10,
                    borderRadius: 4,
                    marginRight: 10,
                    backgroundColor: "Crimson",
                    color: "white",
                    marginTop: 10,
                  }}
                >
                  {clicked !== "None" &&
                    czNuts.find((itm) => itm.code === clicked) &&
                    czNuts.find((itm) => itm.code === clicked).nuts}
                  {clicked !== "None" &&
                    bohemiaNuts.find((itm) => itm.code === clicked) &&
                    bohemiaNuts.find((itm) => itm.code === clicked).nuts}
                </strong>
              )}

              <strong
                style={{
                  padding: 4,
                  paddingRight: 10,
                  paddingLeft: 10,
                  borderRadius: 4,
                  backgroundColor: "Crimson",
                  color: "white",
                  marginTop: 10,
                }}
              >
                {moment(casesCz[casesCz.length - 1].date, "YYYY-MM-DD")
                  .subtract(Math.abs(daySwitch), "days")
                  .format("D MMM YYYY")}
              </strong>
            </div>
          </div>
        </Container>

        {selectedMap === "cazuri" && (
          <FormControlLabel
            control={
              <Switch
                checked={mapSwitch}
                onChange={() => setMapSwitch(!mapSwitch)}
                name="cpm"
                color="secondary"
              />
            }
            label={
              <div style={{ fontSize: 15 }}>
                {props.lang === "ro" ? (
                  <span>
                    {selectedMap === "cazuri" ? "cazuri" : "decese"} per 100 000
                    de locuitori
                  </span>
                ) : (
                  <span>
                    {selectedMap === "cazuri" ? "new cases" : "deaths"} per 100
                    000 inhabitants
                  </span>
                )}
              </div>
            }
            style={{ marginBottom: 10, marginTop: 0 }}
          />
        )}

        {/* -------------- */}

        {/* <Typography variant = "h5" style={{marginBottom: 70}}>
          {moment(moment().subtract(1, 'days').startOf('day')).add(daySwitch, 'days').format('DD / MM / YYYY')}
        </Typography> */}

        {/* -------------- */}
        {/* <Typography
          style={{ fontWeight: 400, marginTop: 0, textAlign: "left" }}
          variant="subtitle1"
        >
          {props.lang === "ro" ? (
            <span>
              <strong>Alege o zi din ultima lună</strong> pentru a o afișa pe
              hartă:
            </span>
          ) : (
            <span>
              <strong>Select a day in the past month</strong> to display it on
              the map:
            </span>
          )}
        </Typography> */}

        <div
          style={{
            marginTop: 0,
            marginBottom: 0,
            paddingLeft: 10,
            paddingRight: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Slider
            value={daySwitch}
            onChange={handleSlider}
            track="inverted"
            defaultValue={0}
            aria-labelledby="discrete-slider"
            step={1}
            marks={props.lang === "ro" ? timelineMarks : timelineMarksEn}
            min={-28}
            max={0}
            color="secondary"
            style={{ width: "100%" }}
          />
        </div>

        <Divider style={{ marginBottom: 50, marginTop: 40 }} />
        <Container
          style={{
            maxWidth: "800px",
            textAlign: "left",
            padding: 0,
            marginTop: 20,
          }}
        >
          <div
            className="notranslate"
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: 0,
            }}
          >
            <FormControl
              style={{ marginBottom: 20, marginTop: 0, marginRight: 10 }}
            >
              <Select
                style={{ fontSize: "1.5rem", fontWeight: 700, borderBottom: 0 }}
                labelId="jduet-label"
                disableUnderline
                color="secondary"
                id="jduet-select"
                value={clicked}
                onChange={(event) => setClicked(event.target.value)}
              >
                <MenuItem key="None" value="None">
                  {props.lang === "ro" ? (
                    <strong>Toată țara</strong>
                  ) : (
                    <strong>National data</strong>
                  )}
                </MenuItem>
                {czNuts.map((j) => (
                  <MenuItem key={j.code} value={j.code}>
                    {j.nuts}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {clicked !== "None" ? (
              <Chip
                onClick={() => setClicked("None")}
                style={{
                  backgroundColor: "Crimson",
                  color: "white",
                  marginBottom: 20,
                  marginLeft: 0,
                  border: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
                label={<strong>RESET</strong>}
                icon={<Refresh style={{ color: "white" }} />}
              />
            ) : (
              <Chip
                style={{
                  color: "#555",
                  marginBottom: 20,
                  marginLeft: 0,
                  border: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
                label={
                  <strong>
                    {props.lang === "ro" ? (
                      <strong>ALEGE JUDEȚ</strong>
                    ) : (
                      <strong>SELECT REGION</strong>
                    )}
                  </strong>
                }
              />
            )}
          </div>

          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 20,
              marginTop: 0,
              color: "grey",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro" ? "Actualizat: " : "Updated: "}
            {updateTime.main}
          </Typography>
          {/* 
          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              alignContent: "left",
              flexShrink: 0,
              marginBottom: 30,
              marginTop: 20,
            }}
          >
            <Typography
              className={`${classes.redBottom} ${classes.infoBubble}`}
              variant="h6"
            >
              <strong style={{ fontSize: "1.2rem" }}>
                {new Intl.NumberFormat("ro-RO").format(currentTotal)}
              </strong>
              {props.lang === "ro" ? " cazuri" : " cases"}
            </Typography>
            <Typography
              className={`${classes.redBottom} ${classes.infoBubble}`}
              variant="h6"
            >
              <strong style={{ fontSize: "1.2rem" }}>
                {new Intl.NumberFormat("ro-RO").format(dailyTotal)}
              </strong>
              {props.lang === "ro" ? " cazuri noi" : " new cases"}
            </Typography>
            {clicked === "None" && (
              <Typography
                className={`${classes.blackBottom} ${classes.infoBubble}`}
                variant="h6"
              >
                <strong style={{ fontSize: "1.2rem" }}>
                  {new Intl.NumberFormat("ro-RO").format(currentTotalDead)}
                </strong>
                {props.lang === "ro" ? " decese" : " deaths"}
              </Typography>
            )}
            {clicked === "None" && (
              <Typography
                className={`${classes.blackBottom} ${classes.infoBubble}`}
                variant="h6"
              >
                <strong style={{ fontSize: "1.2rem" }}>
                  {new Intl.NumberFormat("ro-RO").format(dailyTotalDead)}
                </strong>
                {props.lang === "ro" ? " decese noi" : " new deaths"}
              </Typography>
            )}

            {clicked === "None" && (
              <Fragment>
                <Typography
                  className={`${classes.greenBottom} ${classes.infoBubble}`}
                  variant="h6"
                >
                  <strong style={{ fontSize: "1.2rem" }}>
                    {new Intl.NumberFormat("ro-RO").format(
                      currentTotalVindecati
                    )}
                  </strong>
                  {props.lang === "ro" ? " vindecări" : " recovered"}
                </Typography>
                <Typography
                  className={`${classes.greenBottom} ${classes.infoBubble}`}
                  variant="h6"
                >
                  <strong style={{ fontSize: "1.2rem" }}>
                    {new Intl.NumberFormat("ro-RO").format(dailyTotalVindecati)}
                  </strong>
                  {props.lang === "ro" ? " vindecări noi" : " new recoveries"}
                </Typography>
                <Typography
                  className={`${classes.yellowBottom} ${classes.infoBubble}`}
                  variant="h6"
                >
                  <strong style={{ fontSize: "1.2rem" }}>
                    {new Intl.NumberFormat("ro-RO").format(
                      currentTotal - currentTotalDead - currentTotalVindecati
                    )}
                  </strong>
                  {props.lang === "ro" ? " cazuri active" : " active cases"}
                </Typography>
              </Fragment>
            )}
          </div> */}

          {/* <Typography
            className="notranslate"
            style={{ fontWeight: 700, marginTop: 10 }}
            variant="subtitle1"
          >
            {props.lang === "ro" ? "Alege un grafic:" : "Select a map"}
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup
              className="notranslate"
              style={{
                width: "auto",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                marginTop: 0,
              }}
              // aria-label="gender"
              name="gender1"
              value={selectedChart}
              onChange={(event) => setSelectedChart(event.target.value)}
            >
              <FormControlLabel
                value="total"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "totaluri" : "totals"}
                  </div>
                }
              />
              <FormControlLabel
                value="daily"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "cifre zilnice" : "daily data"}
                  </div>
                }
              />
            </RadioGroup>
          </FormControl>
        */}
        </Container>

        <div className="notranslate">
          <Fragment>
            <Typography
              style={{
                fontWeight: 400,
                marginBottom: 10,
                marginTop: 10,
                textAlign: "left",
              }}
              variant="subtitle1"
            >
              {props.lang === "ro" ? (
                <span>
                  <strong>Grafic: </strong>cazuri{" "}
                  {clicked === "None" ? "și decese" : ""} în ultimele 12
                  săptămâni{" "}
                  <span>
                    {clicked !== "None"
                      ? "în județul " + judNames[clicked]
                      : null}
                  </span>{" "}
                  (date zilnice)
                </span>
              ) : (
                <span>
                  Daily new <strong>cases</strong> in the past 12 weeks:
                </span>
              )}
            </Typography>

            <DailyChartCz
              clicked={clicked}
              data={roLineData}
              lang={props.lang}
            />
          </Fragment>
        </div>
        <div className="notranslate">
          <Fragment>
            <Typography
              style={{
                fontWeight: 400,
                marginBottom: 10,
                marginTop: 10,
                textAlign: "left",
              }}
              variant="subtitle1"
            >
              {props.lang === "ro" ? (
                <span>
                  <strong>Grafic: </strong>decese în ultimele 12 săptămâni{" "}
                  <span>
                    {clicked !== "None"
                      ? "în județul " + judNames[clicked]
                      : null}
                  </span>{" "}
                  (date zilnice)
                </span>
              ) : (
                <span>
                  Daily new <strong>deaths</strong> in the past 12 weeks
                  (regional data has a 7 day delay):
                </span>
              )}
            </Typography>

            <DailyChartDeathsCz
              clicked={clicked}
              data={roLineData}
              lang={props.lang}
            />
          </Fragment>
        </div>
      </Container>

      <Container style={{ maxWidth: 800, textAlign: "left" }}>
        {/* <Typography
          style={{ color: "#333", marginTop: 10 }}
          target="_blank"
          variant="subtitle2"
          component="a"
          href="https://docs.google.com/spreadsheets/d/1TafrOhj0AivTvTgNUGett6gEzn7m4mO2Urr-xYaIY4k/edit?usp=sharing"
        >
          {props.lang === "ro"
            ? "Vezi datele complete (open data)"
            : "View complete dataset (open data)"}
        </Typography> */}
        <Divider style={{ marginTop: 40, marginBottom: 40 }} />
        {/* 
        <JustArticles
          clicked={clicked}
          setClicked={setClicked}
          lang={props.lang}
        />

        <Divider style={{ marginTop: 40, marginBottom: 40 }} /> */}

        <Typography
          style={{ marginTop: 20, marginBottom: 10, color: "grey" }}
          variant="subtitle2"
        >
          <strong> {props.lang === "ro" ? "Surse:" : "Sources"}</strong>
        </Typography>
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="https://onemocneni-aktualne.mzcr.cz/covid-19"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          {props.lang === "ro"
            ? "1. Ministerul sănătății"
            : "1. Czech Health Ministry"}
        </Typography>

        <Divider style={{ marginBottom: 40, marginTop: 40 }} />

        <div style={{ textAlign: "center", marginBottom: 40 }}>
          <Typography variant="subtitle2">Tech by</Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://addictad.ro"
          >
            <img
              style={{ height: 16, marginTop: 5 }}
              src="/images/logo-400px.png"
              alt=""
            />
          </a>
        </div>
      </Container>
    </div>
  );
}

export default RoMap;
