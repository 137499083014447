import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Refresh from "@material-ui/icons/Refresh";
import { request } from "graphql-request";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import "./App.css";
import ChipMenu from "./components/ChipMenu";
import { generalQuery, queryArticles } from "./gql/queries";
import judNames from "./sources/judNames.json";

ReactGA.initialize("UA-1029715-12");
const useStyles = makeStyles({
  infoBubble: {
    display: "inline-block",
    marginTop: 4,
    marginBottom: 4,
    marginRight: 10,
    width: window.innerWidth > 500 ? "23%" : "47%",
    fontSize: 16,
    paddingTop: 2,
    paddingBottom: 2,
    opacity: 0.9,
    textAlign: "left",
  },
  redBottom: {
    borderBottom: "4px solid Crimson",
  },
  yellowBottom: {
    borderBottom: "4px solid #FFC107",
  },
  greenBottom: {
    borderBottom: "4px solid rgb(86, 159, 57)",
  },
  blueBottom: {
    borderBottom: "4px solid rgb(60, 153, 220)",
  },
  blackBottom: {
    borderBottom: "4px solid #333",
  },
});

function App(props) {
  const classes = useStyles();
  let history = useHistory();
  const urlPiece = useParams();
  console.log(urlPiece);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const [clicked, setClicked] = useState(
    urlPiece.judet ? urlPiece.judet : "None"
  );

  const [currentArticles, setCurrentArticles] = useState([]);
  const [hist, setHist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [spacerVal, setSpacerVal] = useState(10);

  useEffect(() => {
    if (urlPiece.judet) {
      setClicked(urlPiece.judet);
      ReactGA.pageview(window.location.pathname);
    } else {
      setClicked("None");
      ReactGA.pageview(window.location.pathname);
    }
  }, [urlPiece]);

  useEffect(() => {
    const extractData = async (data) => {
      let result = await Promise.resolve(data).then(
        (value) => value.articles_aggregate.aggregate.count
      );
      return result;
    };

    if (clicked && clicked !== "None") {
      setHist([]);
      request("https://www.add-dev.me/v1/graphql", queryArticles, {
        judet: clicked === "ro-if" ? "ro-b" : clicked,
        spacer: spacerVal,
      })
        .then((data) => {
          setCurrentArticles(data.articles);
        })
        .then(() => setLoading(false));
    } else if (clicked && clicked === "None") {
      setHist([]);
      request("https://www.add-dev.me/v1/graphql", generalQuery, {
        spacer: spacerVal,
      })
        .then((data) => {
          setCurrentArticles(data.articles);
        })
        .then(() => setLoading(false));
    }
  }, [clicked, spacerVal]);

  useEffect(() => {
    setSpacerVal(10);
    setLoading(true);
  }, [clicked]);

  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <Typography
        variant="body2"
        style={{
          fontSize: 12,
          backgroundColor: "#dd1b14",
          color: "white",
          padding: 4,
          width: "100%",
        }}
      >
        {props.lang === "ro"
          ? "Această aplicație folosește cookies pentru a colecta statistici de utilizare. Prin folosirea aplicației îți exprimi acordul cu "
          : "This application uses cookies to collect usage statistics. By using the application you accept "}
        <Link
          style={{ color: "white", margin: 0 }}
          to={props.lang === "ro" ? "/cookies/" : "/en/cookies/"}
        >
          {props.lang === "ro"
            ? "politica noastră privind datele personale"
            : "our personal data policy"}
        </Link>
        .
      </Typography>
      <Container style={{ maxWidth: "800px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          <img
            style={{ maxWidth: "80%", alignSelf: "center" }}
            src="/images/Monitorizare-Coronavirus.png"
            alt=""
          />
          <img
            style={{ maxHeight: "60px", alignSelf: "center" }}
            src="/images/hortensia-2210.png"
            alt=""
            onClick={() => window.location.reload()}
          />
        </div>
        <Divider style={{ marginBottom: 30, marginTop: 20 }} />
        <ChipMenu active={"presa"} lang={props.lang} />
      </Container>
      <Container style={{ maxWidth: 800, textAlign: "left" }}>
        {/* <Divider style={{ marginBottom: 30 }} /> */}
        <FormControl style={{ marginBottom: 20, marginTop: 0 }}>
          <Select
            style={{ fontSize: "1.5rem", fontWeight: 700, borderBottom: 0 }}
            labelId="jduet-label"
            disableUnderline
            color="secondary"
            id="jduet-select"
            value={clicked}
            onChange={(event) => setClicked(event.target.value)}
          >
            <MenuItem key="None" value="None">
              {props.lang === "ro" ? "Toată țara" : "Entire country"}
            </MenuItem>
            {Object.keys(judNames).map(
              (j) =>
                j !== "ro-if" && (
                  <MenuItem key={j} value={j}>
                    {props.lang === "ro"
                      ? judNames[j]
                      : judNames[j] + " county"}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
        {clicked !== "None" ? (
          <Chip
            onClick={() => setClicked("None")}
            style={{
              backgroundColor: "Crimson",
              color: "white",
              marginBottom: 0,
              marginTop: 6,
              marginLeft: 10,
              border: 0,
              paddingLeft: 2,
              paddingRight: 2,
            }}
            label={<strong>RESET</strong>}
            icon={<Refresh style={{ color: "white" }} />}
          />
        ) : (
          <Chip
            style={{
              // backgroundColor: "Crimson",
              color: "#555",
              marginBottom: 0,
              marginTop: 6,
              marginLeft: 10,
              border: 0,
              paddingLeft: 2,
              paddingRight: 2,
            }}
            label={
              <strong>
                {props.lang === "ro" ? "ALEGE JUDEȚ" : "SELECT COUNTY"}
              </strong>
            }
            // icon={<Refresh style={{ color: "white" }} />}
          />
        )}
        <Typography
          style={{ fontWeight: 400, marginBottom: 10 }}
          variant="subtitle1"
        >
          {props.lang === "ro" ? (
            <span>
              Monitorizăm continuu <strong>290 de surse</strong> din toată țara
              și compunem <strong>fluxuri per județ</strong> cu informații
              locale despre epidemia de COVID-19.
            </span>
          ) : (
            <span>
              We continuously monitor <strong>290 sources</strong> from all over
              the country and compose <strong>news feeds per county</strong> cu
              with local information about the COVID-19 epidemic.
            </span>
          )}
        </Typography>
        {/* <PresaLine data={hist} /> */}
        {/* <Divider style={{ marginBottom: 40, marginTop: 30 }} />
        <Typography style={{ marginBottom: 10 }} variant="h5">
          <strong>Articole</strong>{" "}
        </Typography> */}
        {props.lang === "en" && (
          <Typography
            style={{ marginBottom: 20, marginTop: 20 }}
            variant="subtitle1"
          >
            <strong>Please note:</strong> the headlines below are in Romanian.
            You can use your browser's translation functionality to better
            understand the content.
          </Typography>
        )}

        <Typography
          style={{ marginBottom: 30, marginTop: 30 }}
          variant="subtitle1"
        >
          {props.lang === "ro"
            ? "Cele mai noi articole din regiunea curentă:"
            : "The newest articles in the current region:"}
        </Typography>
        {loading ? (
          <LinearProgress
            color="secondary"
            style={{ marginTop: 20, marginBottom: 20 }}
          />
        ) : null}
        {currentArticles.map((art) => (
          <Card
            key={art.isoDate}
            style={{
              marginBottom: 20,
              padding: 10,
              borderTop: "6px solid Crimson",
              backgroundColor: "rgb(248, 248, 248)",
            }}
          >
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={art.link}
              style={{ color: "#333", textDecoration: "none" }}
            >
              <CardContent>
                <Typography variant="subtitle2">
                  <strong>{judNames[art.judet]}</strong> |{" "}
                  {moment.unix(art.isoDate).format("HH:mm | DD MMM")} |{" "}
                  <u>
                    {art.link
                      .replace("https://", "")
                      .replace("http://", "")
                      .slice(
                        0,
                        art.link
                          .replace("https://", "")
                          .replace("http://", "")
                          .indexOf("/")
                      )}
                  </u>
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontSize: 16, marginTop: 10 }}
                >
                  <strong>{unescape(art.title)}</strong>
                </Typography>
              </CardContent>
            </a>
          </Card>
        ))}
        <Container fullWidth style={{ textAlign: "center" }}>
          <Chip
            style={{
              backgroundColor: "Crimson",
              color: "white",
              marginBottom: 10,
              marginTop: 10,
              marginRight: 6,
              paddingLeft: 2,
              paddingRight: 2,
              border: null,
              textAlign: "center",
            }}
            onClick={() => setSpacerVal(spacerVal + 10)}
            label={
              <strong>
                {props.lang === "ro" ? "încarcă mai multe" : "load more"}
              </strong>
            }
          />
        </Container>
        <Divider style={{ marginBottom: 40, marginTop: 40 }} />
        <div style={{ textAlign: "center", marginBottom: 40 }}>
          <Typography variant="subtitle2">Tech by</Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://addictad.ro"
          >
            <img
              style={{ height: 16, marginTop: 5 }}
              src="/images/logo-400px.png"
              alt=""
            />
          </a>
        </div>
      </Container>
    </div>
  );
}

export default App;
