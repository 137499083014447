import { scaleQuantize } from "d3-scale";
import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import "../../mapstyles.css";

const geoUrl = "/rogeomap.json";

export default function GeoMap(props) {
  const [localState, setLocalState] = React.useState(props);
  const [localCazuri, setLocalCazuri] = React.useState([]);
  const [localJudete, setLocalJudete] = React.useState([]);
  const [localMapSwitch, setLocalMapSwitch] = React.useState(false);
  React.useEffect(() => {
    // setLocalMapSwitch(props.mapSwitch);
    setLocalState(props);
    if (props.cazuri && props.cazuri.length > 0) {
      let caseColl = [];
      props.cazuri.forEach((caz) => {
        let obj = {};
        obj.markerOffset = 4;
        obj.name = caz.cazuri_azi;
        obj.coordinates = [caz.long, caz.lat];
        caseColl.push(obj);
      });
      let judColl = {};
      props.cazuri.forEach((caz) => {
        judColl[caz.judet] = caz.cazuri_azi;
      });
      setLocalJudete(judColl);
      setLocalCazuri(caseColl);
    }
  }, [props]);

  const colorScale = scaleQuantize()
    .domain(localMapSwitch ? [0, 30] : [0, 400])
    .range(["#f6bdc0", "#f1959b", "#f07470", "#ea4c46", "#dc1c13", "#97130e"]);

  // console.log(localState);
  // console.log(localCazuri);
  return (
    <div>
      <ComposableMap
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: [-25, -45.8, 0],
          scale: 6500,
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const cur =
                localJudete[
                  geo.properties.NAME_1 === "ro-if"
                    ? "ro-b"
                    : geo.properties.NAME_1
                ];
              return (
                <Geography
                  onClick={() =>
                    props.setClicked(
                      geo.properties.NAME_1 === "ro-if"
                        ? "ro-b"
                        : geo.properties.NAME_1
                    )
                  }
                  key={geo.rsmKey}
                  geography={geo}
                  fill={cur > 0 ? colorScale(cur) : "#cbcbcb"}
                  stroke="#FFFFFF"
                  strokeWidth={1}
                  style={{
                    hover: {
                      opacity: 0.8,
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
        {localCazuri.map(
          ({ name, coordinates, markerOffset }) =>
            name !== 0 &&
            name !== "0.0" && (
              <Marker key={Math.random()} coordinates={coordinates}>
                <circle
                  r={18}
                  fill="#555"
                  stroke="#fff"
                  strokeWidth={0}
                  opacity={0.8}
                />
                <text
                  textAnchor="middle"
                  y={markerOffset}
                  style={{
                    fontFamily: "system-ui",
                    fill: "#fff",
                    fontWeight: 700,
                    fontSize: 13,
                  }}
                >
                  {name}
                </text>
              </Marker>
            )
        )}
      </ComposableMap>
    </div>
  );
}
