import React, { useEffect, useState } from "react";
import moment from "moment";
import LegendMyBig from "../../components/LegendMyBig";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  LineChart,
  Line,
  Area,
  ComposedChart,
} from "recharts";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
export default function ChartBarTeste(props) {
  const [localData, setLocalData] = useState();
  const [localClicked, setLocalClicked] = useState("None");
  const [maxVal, setMaxVal] = useState(0);
  const [maxDeathsVal, setMaxDeathsVal] = useState(0);
  const [chartSwitch, setChartSwitch] = useState(true);
  useEffect(() => {
    let localColl = props.data;
    localColl.forEach(
      (itm) => (itm.dataOk = moment(itm.data, "MM/DD/YYYY").format("DD/MM 'YY"))
    );
    setLocalData(localColl);
    setLocalClicked(props.clicked);
  }, [props]);
  useEffect(() => {
    if (localData) {
      let max = Math.max(...localData.map((itm) => parseInt(itm.cazuriAzi)));
      let maxDeaths = Math.max(
        ...localData.map((itm) => parseInt(itm.deceseAzi))
      );
      setMaxVal(max);
      setMaxDeathsVal(maxDeaths);
    } else {
      setMaxVal(0);
      setMaxDeathsVal(0);
    }
  }, [localData]);

  return (
    <div>
      {/* <div style={{ marginBottom: 20, marginTop: 0 }}>
        <FormControlLabel
          control={
            <Switch
              checked={chartSwitch}
              onChange={() => setChartSwitch(!chartSwitch)}
              name="cpm"
              color="secondary"
            />
          }
          label={<div style={{ fontSize: 20 }}>scară dinamică</div>}
        />{" "}
      </div> */}
      <LegendMyBig
        content={[
          {
            name: props.lang === "ro" ? "cazuri" : "cases",
            color: "#dd1a18",
          },
          {
            name: props.lang === "ro" ? "decese" : "deaths",
            color: "#333",
          },
        ]}
      />
      <div
        style={{
          // marginLeft: -30,
          height: "62.5vh",
          display: "flex",
          justifyContent: "left",
        }}
      >
        <ResponsiveContainer
          // style={{ marginLeft: -30 }}
          width="100%"
          height="100%"
        >
          <ComposedChart
            width="100%"
            height={350}
            data={
              localData
                ? localData.sort((a, b) => (a.data_unix < b.data_unix ? -1 : 1))
                : []
            }
            margin={{
              top: 0,
              right: 10,
              left: 10,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              tick={{ fontSize: 20 }}
              interval={90}
              dataKey="dataOk"
              tickMargin={5}
            />

            <YAxis
              hide={false}
              tick={{ fontSize: 20 }}
              // domain={[0, maxVal]}
              domain={
                chartSwitch ? [0, maxVal] : [0, maxVal > 1000 ? maxVal : 1000]
              }
              allowDataOverflow={true}
              yAxisId="left"
              orientation="left"
              stroke="#DC143C"
              tickFormatter={(tick) =>
                Intl.NumberFormat().format(tick.toFixed(1))
              }
            />
            <YAxis
              hide={false}
              tick={{ fontSize: 20 }}
              // domain={[0, maxDeathsVal * 4]}
              // domain={[0, 200]}
              domain={
                chartSwitch
                  ? [0, maxDeathsVal * 4]
                  : [0, maxDeathsVal > 200 ? maxDeathsVal * 4 : 200]
              }
              tickMargin={10}
              yAxisId="right"
              orientation="right"
              tickFormatter={(tick) =>
                Intl.NumberFormat().format(tick.toFixed(1))
              }
            />

            <Tooltip
              itemStyle={{ fontSize: 14 }}
              isAnimationActive={false}
              formatter={(lbl) => {
                return Intl.NumberFormat().format(lbl);
              }}
            />

            <Line
              dot={false}
              // key={Math.random()}
              isAnimationActive={false}
              type="monotone"
              dataKey="deceseAzi"
              stroke="rgba(0,0,0,0.8)"
              name={props.lang === "ro" ? "decese noi" : "new deaths"}
              strokeWidth={2}
              activeDot={{ r: 8 }}
              yAxisId="right"
            />
            <Line
              dot={false}
              // key={Math.random()}
              isAnimationActive={false}
              type="monotone"
              dataKey="cazuriAzi"
              stroke="rgba(220, 20, 60, 0.9)"
              name={props.lang === "ro" ? "cazuri noi" : "new cases"}
              strokeWidth={2}
              activeDot={{ r: 8 }}
              yAxisId="left"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div style={{ marginTop: 40 }}></div>
    </div>
  );
}
