import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Refresh from "@material-ui/icons/Refresh";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import "./App.css";
import ChipMenu from "./components/ChipMenu";
import Header from "./components/common/Header";
import LegendMy from "./components/LegendMy";
import CaseMapRo from "./components/romania/CaseMapRo";
import DailyChart from "./components/romania/DailyChart";
import DeadMapRo from "./components/romania/DeadMapRo";
import RoLine from "./components/romania/RoLine";
import VaccinBar from "./components/romania/VaccinBar";
import VariantsBar from "./components/romania/VariantsBar";
import VaccMonth from "./components/romania/VaccMonth";
import DeceseMap from "./components/vaccin/DeceseMap";
import updateTime from "./data/updated.json";

import judgps from "./sources/judgps.json";
import judNames from "./sources/judNames.json";
import timelineMarks from "./sources/timelineMarks.json";
import timelineMarksEn from "./sources/timelineMarksEn.json";
// import updateTime from "./data/updated.json";
const useStyles = makeStyles({
  infoBubble: {
    display: "inline-block",
    marginTop: 4,
    marginBottom: 4,
    marginRight: 10,
    width: window.innerWidth > 500 ? "25%" : "47%",
    fontSize: 16,
    paddingTop: 2,
    paddingBottom: 2,
    opacity: 0.9,
    textAlign: "left",
  },
  redBottom: {
    borderBottom: "4px solid Crimson",
  },
  yellowBottom: {
    borderBottom: "4px solid #FFC107",
  },
  greenBottom: {
    borderBottom: "4px solid rgb(86, 159, 57)",
  },
  blueBottom: {
    borderBottom: "4px solid rgb(60, 153, 220)",
  },
  blackBottom: {
    borderBottom: "4px solid #333",
  },
  orangeBottom: {
    borderBottom: "4px solid #e67e22",
  },
});

function RoMap(props) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const classes = useStyles();
  // per judet: cazuri_toal, decese_total, judet, lat, long
  const [cazuri, setCazuri] = useState([]);
  const [roLineData, setRoLineData] = useState([]);
  const [allData, setAllData] = useState([]);

  // toate cazurile de toate tipurile

  const [currentTotal, setCurrentTotal] = useState(0);
  const [currentAti, setCurrentAti] = useState(0);
  const [dailyTotal, setDailyTotal] = useState(0);
  const [currentTotalDead, setCurrentTotalDead] = useState(0);
  const [dailyTotalDead, setDailyTotalDead] = useState(0);
  const [currentTotalVindecati, setCurrentTotalVindecati] = useState(0);
  const [dailyTotalVindecati, setDailyTotalVindecati] = useState(0);
  const [selectedMap, setSelectedMap] = useState("decese");
  const [selectedChart, setSelectedChart] = useState("daily");
  const [vaxChart, setVaxChart] = useState("month");
  const [clicked, setClicked] = useState("None");
  const [mapSwitch, setMapSwitch] = useState(false);
  const [chartSwitch, setChartSwitch] = useState(false);
  const [daySwitch, setDaySwitch] = useState(0);
  const [vaccinData, setvaccinData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const getVaxData = async () => {
    await fetch(`/data/vaccin.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        data.forEach(
          (itm) =>
            (itm.dataOk = moment(itm.data, "MM/DD/YYYY").format("DD/MM 'YY"))
        );

        setvaccinData(data);
      });
    await fetch(`/data/variants_ro.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        data.forEach(
          (itm) => (itm.date = moment(itm.week, "YYYY-WW").format("MMM-DD"))
        );
        setVariantData(data);
      });
  };
  useEffect(() => {
    getVaxData();
  }, []);
  // on page load -> cazuri, allCases, updated, updatedTeste, teste
  useEffect(() => {
    let getData = async () => {
      let localCopy;
      await fetch(`/data/dailyCalc.json`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(
          (data) =>
            (localCopy = data
              .filter((itm) => itm.judet !== "necomunicat")
              .sort((a, b) => (a.data_unix > b.data_unix ? -1 : 1)))
        );

      let firstTenDays = [];
      let caseNoTotal = [];

      localCopy.forEach((itm) => {
        firstTenDays.push(itm);
        itm.judet === "total" && caseNoTotal.push(itm);
      });

      caseNoTotal = caseNoTotal.sort((a, b) =>
        a.data_unix > b.data_unix ? -1 : 1
      );

      setAllData(firstTenDays);
      // setRoLineData(caseNoTotal);
      // setCurrentAti(caseNoTotal[caseNoTotal.length - 1].ati);
      // setCurrentTotal(caseNoTotal[caseNoTotal.length - 1].cazuriTotal);
      // setCurrentTotalDead(caseNoTotal[caseNoTotal.length - 1].deceseTotal);
      // setCurrentTotalVindecati(caseNoTotal[caseNoTotal.length - 1].vindTotal);
      // setDailyTotal(caseNoTotal[caseNoTotal.length - 1].cazuriAzi);
      // setDailyTotalDead(caseNoTotal[caseNoTotal.length - 1].deceseAzi);
      // setDailyTotalVindecati(caseNoTotal[caseNoTotal.length - 1].vindAzi);
    };

    getData();
  }, []);

  useEffect(() => {
    let caseNoPerJud = [];
    allData
      .slice(-1 * daySwitch * 43, (-1 * daySwitch + 1) * 43)
      .filter((itm) => itm.judet !== "total" && itm.judet !== "ro-if")
      .forEach((el) => {
        if (el.judet !== "necunoscut") {
          let obj = {
            judet: el.judet,
            lat: judgps.filter((i) => i.judet === el.judet)[0].lat,
            long: judgps.filter((i) => i.judet === el.judet)[0].long,
            cazuri_azi: el.cazuriAzi,
            decese_azi: el.deceseAzi,
            data: el.data,
          };
          if (mapSwitch) {
            obj.cazuri_total = Math.round(
              el.cazuriTotal /
                judgps.filter((itm) => itm.judet === obj.judet)[0].pop_100k
            );
            obj.decese_total = (
              el.deceseTotal /
              judgps.filter((itm) => itm.judet === obj.judet)[0].pop_100k
            ).toFixed(1);
          } else {
            obj.cazuri_total = el.cazuriTotal;
            obj.decese_total = el.deceseTotal;
          }
          caseNoPerJud.push(obj);
        }
      });

    setCazuri(caseNoPerJud);
  }, [mapSwitch, allData, daySwitch]);

  useEffect(() => {
    let caseNoTotal = [];
    let ldata = [];
    ldata = allData;
    if (clicked !== "None") {
      ldata &&
        ldata.forEach((itm) => {
          itm.judet === clicked && caseNoTotal.push(itm);
        });
    } else {
      ldata &&
        ldata.forEach((itm) => {
          itm.judet === "total" && caseNoTotal.push(itm);
        });
    }
    caseNoTotal = caseNoTotal.sort((a, b) => {
      if (a.data_unix > b.data_unix) {
        return -1;
      } else {
        return 1;
      }
    });

    if (caseNoTotal.length > 0) {
      setRoLineData(caseNoTotal);
      setCurrentAti(caseNoTotal[0].ati);
      setCurrentTotal(caseNoTotal[0].cazuriTotal);
      setCurrentTotalDead(caseNoTotal[0].deceseTotal);
      setCurrentTotalVindecati(caseNoTotal[0].vindTotal);
      setDailyTotal(caseNoTotal[0].cazuriAzi);
      setDailyTotalDead(caseNoTotal[0].deceseAzi);
      setDailyTotalVindecati(caseNoTotal[0].vindAzi);
    }
    clicked !== "None" &&
      ReactGA.event({
        category: "Romania",
        action: clicked,
      });
  }, [clicked, allData]);

  useEffect(() => {
    let caseNoPerJud = [];
    allData
      .slice(0, 43)
      .filter((itm) => itm.judet !== "total" && itm.judet !== "ro-if")
      .forEach((el) => {
        let obj = {
          judet: el.judet,
          lat: judgps.filter((i) => i.judet === el.judet)[0].lat,
          long: judgps.filter((i) => i.judet === el.judet)[0].long,
          cazuri_azi: el.cazuriAzi,
          decese_azi: el.deceseAzi,
          data: el.data,
        };
        if (mapSwitch) {
          obj.cazuri_total = Math.round(
            el.cazuriTotal /
              judgps.filter((itm) => itm.judet === obj.judet)[0].pop_100k
          );
          obj.decese_total = (
            el.deceseTotal /
            judgps.filter((itm) => itm.judet === obj.judet)[0].pop_100k
          ).toFixed(1);
        } else {
          obj.cazuri_total = el.cazuriTotal;
          obj.decese_total = el.deceseTotal;
          obj.cazuri_azi = el.cazuriAzi;
          obj.decese_azi = el.deceseAzi;
          // obj.decese_azi = el.deceseAzi
        }
        caseNoPerJud.push(obj);
      });
    setCazuri(caseNoPerJud);
  }, [mapSwitch, allData]);

  const handleSlider = (event, newValue) => {
    setDaySwitch(newValue);
  };
  // console.log(variantData[0]);
  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <Typography
        variant="body2"
        style={{
          fontSize: 12,
          backgroundColor: "#dd1b14",
          color: "white",
          padding: 4,
          width: "100%",
        }}
      >
        {props.lang === "ro"
          ? "Această aplicație folosește cookies pentru a colecta statistici de utilizare. Prin folosirea aplicației îți exprimi acordul cu "
          : "This application uses cookies to collect usage statistics. By using the application you accept "}
        <Link
          style={{ color: "white", margin: 0 }}
          to={props.lang === "ro" ? "/cookies/" : "/en/cookies/"}
        >
          {props.lang === "ro"
            ? "politica noastră privind datele personale"
            : "our personal data policy"}
        </Link>
        .
      </Typography>
      <Container style={{ maxWidth: "800px" }}>
        <Header />
        <ChipMenu active={"ro"} lang={props.lang} />
        <Container
          style={{
            textAlign: "left",
            padding: 0,
            marginTop: 20,
          }}
        >
          <Typography style={{ marginBottom: 10, marginTop: 30 }} variant="h5">
            <strong>
              {props.lang === "ro"
                ? "Epidemia în România"
                : "The epidemic in Romania"}
            </strong>
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 20,
              marginTop: 5,
              color: "grey",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro" ? "Actualizat: " : "Updated: "}{" "}
            {updateTime.main}
          </Typography>

          <Typography
            style={{ fontWeight: 700, marginTop: 20 }}
            variant="subtitle1"
          >
            {props.lang === "ro" ? "Alege o hartă:" : "Select a map:"}
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup
              style={{
                width: "auto",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                marginTop: 0,
              }}
              aria-label="gender"
              name="gender1"
              value={selectedMap}
              onChange={(event) => setSelectedMap(event.target.value)}
            >
              <FormControlLabel
                value="cazuri"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "decese noi" : "new deaths"}
                  </div>
                }
              />
              <FormControlLabel
                value="decese"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "cazuri noi" : "new cases"}
                  </div>
                }
              />
            </RadioGroup>
          </FormControl>

          <div>
            {selectedMap === "cazuri" && (
              <Typography
                style={{ fontWeight: 400, marginTop: 0, marginBottom: 10 }}
                variant="subtitle1"
              >
                {props.lang === "ro" ? (
                  <strong>Harta curentă: </strong>
                ) : (
                  <strong>Current map: </strong>
                )}
                {props.lang === "ro"
                  ? "numărul și distribuția cazurilor de COVID-19 în România."
                  : "number and distribution of COVID-19 cases in Romania."}
              </Typography>
            )}
          </div>

          <div>
            {selectedMap === "decese" && (
              <Typography
                style={{ fontWeight: 400, marginTop: 0, marginBottom: 10 }}
                variant="subtitle1"
              >
                {props.lang === "ro" ? (
                  <strong>Harta curentă: </strong>
                ) : (
                  <strong>Current map: </strong>
                )}
                {props.lang === "ro"
                  ? "numărul și distribuția cazurilor de COVID-19 din ultima zi în România."
                  : "number and distribution of COVID-19 cases from the most recent day in Romania."}
              </Typography>
            )}
          </div>

          <Typography
            style={{ fontWeight: 400, marginTop: 0, marginBottom: 20 }}
            variant="subtitle1"
          ></Typography>

          <div style={{ width: "108%", marginLeft: "-4%" }}>
            <div>
              {selectedMap === "cazuri" && (
                <DeceseMap
                  setClicked={setClicked}
                  cazuri={cazuri}
                  mapSwitch={mapSwitch}
                  lang={props.lang}
                />
              )}
            </div>
            <div>
              {selectedMap === "decese" && (
                <DeadMapRo
                  setClicked={setClicked}
                  cazuri={cazuri}
                  mapSwitch={false}
                  lang={props.lang}
                />
              )}
            </div>
          </div>
        </Container>

        {/* -------------- */}
        {/* <Typography variant = "h5" style={{marginBottom: 70}}>
          {moment(moment().subtract(1, 'days').startOf('day')).add(daySwitch, 'days').format('DD / MM / YYYY')}
        </Typography> */}
        <div style={{ marginTop: 0, marginBottom: 20 }}>
          <strong
            style={{
              padding: 4,
              paddingRight: 10,
              paddingLeft: 10,
              borderRadius: 4,
              backgroundColor: "Crimson",
              color: "white",
              marginTop: 10,
            }}
          >
            {moment(cazuri[0] && cazuri[0].data).format("DD/MM 'YY")}
          </strong>
        </div>
        {/* -------------- */}
        <Typography
          style={{ fontWeight: 400, marginTop: 0, textAlign: "left" }}
          variant="subtitle1"
        >
          {props.lang === "ro" ? (
            <span>
              <strong>Alege o zi din ultima lună</strong> pentru a o afișa pe
              hartă:
            </span>
          ) : (
            <span>
              <strong>Select a day in the past month</strong> to display it on
              the map:
            </span>
          )}
        </Typography>
        <div
          style={{
            marginTop: 10,
            marginBottom: 0,
            paddingLeft: 10,
            paddingRight: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Slider
            value={daySwitch}
            onChange={handleSlider}
            track="inverted"
            defaultValue={0}
            aria-labelledby="discrete-slider"
            step={1}
            marks={props.lang === "ro" ? timelineMarks : timelineMarksEn}
            min={-28}
            max={0}
            color="secondary"
            style={{ width: "100%" }}
          />
        </div>
        <Divider style={{ marginBottom: 50, marginTop: 40 }} />
        <Container
          style={{
            maxWidth: "800px",
            textAlign: "left",
            padding: 0,
            marginTop: 20,
          }}
        >
          <div
            className="notranslate"
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: 0,
            }}
          >
            <FormControl
              style={{ marginBottom: 20, marginTop: 0, marginRight: 10 }}
            >
              <Select
                style={{ fontSize: "1.5rem", fontWeight: 700, borderBottom: 0 }}
                labelId="jduet-label"
                disableUnderline
                color="secondary"
                id="jduet-select"
                value={clicked}
                onChange={(event) => setClicked(event.target.value)}
              >
                <MenuItem key="None" value="None">
                  {props.lang === "ro" ? (
                    <strong>Toată țara</strong>
                  ) : (
                    <strong>National data</strong>
                  )}
                </MenuItem>
                {Object.keys(judNames).map(
                  (j) =>
                    j !== "ro-if" && (
                      <MenuItem key={j} value={j}>
                        {judNames[j]} {props.lang === "ro" ? null : "county"}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
            {clicked !== "None" ? (
              <Chip
                onClick={() => setClicked("None")}
                style={{
                  backgroundColor: "Crimson",
                  color: "white",
                  marginBottom: 20,
                  marginLeft: 0,
                  border: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
                label={<strong>RESET</strong>}
                icon={<Refresh style={{ color: "white" }} />}
              />
            ) : (
              <Chip
                style={{
                  color: "#555",
                  marginBottom: 20,
                  marginLeft: 0,
                  border: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
                label={
                  <strong>
                    {props.lang === "ro" ? (
                      <strong>ALEGE JUDEȚ</strong>
                    ) : (
                      <strong>SELECT COUNTY</strong>
                    )}
                  </strong>
                }
              />
            )}
          </div>

          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 10,
              marginTop: 0,
              color: "grey",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro" ? "Actualizat: " : "Updated: "}
            {updateTime.main}
          </Typography>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              alignContent: "left",
              flexShrink: 0,
              marginBottom: 20,
              marginTop: 10,
            }}
          >
            <Typography
              className={`${classes.redBottom} ${classes.infoBubble}`}
              variant="h6"
            >
              <strong style={{ fontSize: "1.2rem" }}>
                {new Intl.NumberFormat("ro-RO").format(currentTotal)}
              </strong>
              {props.lang === "ro" ? " cazuri" : " cases"}
            </Typography>
            <Typography
              className={`${classes.redBottom} ${classes.infoBubble}`}
              variant="h6"
            >
              <strong style={{ fontSize: "1.2rem" }}>
                {new Intl.NumberFormat("ro-RO").format(dailyTotal)}
              </strong>
              {props.lang === "ro" ? " cazuri noi" : " new cases"}
            </Typography>

            {clicked === "None" && (
              <Typography
                className={`${classes.blackBottom} ${classes.infoBubble}`}
                variant="h6"
              >
                <strong style={{ fontSize: "1.2rem" }}>
                  {new Intl.NumberFormat("ro-RO").format(currentTotalDead)}
                </strong>
                {props.lang === "ro" ? " decese" : " deaths"}
              </Typography>
            )}

            <Typography
              className={`${classes.blackBottom} ${classes.infoBubble}`}
              variant="h6"
            >
              <strong style={{ fontSize: "1.2rem" }}>
                {new Intl.NumberFormat("ro-RO").format(dailyTotalDead)}
              </strong>
              {props.lang === "ro" ? " decese noi" : " new deaths"}
            </Typography>

            {clicked === "None" && (
              <Fragment>
                <Typography
                  className={`${classes.greenBottom} ${classes.infoBubble}`}
                  variant="h6"
                >
                  <strong style={{ fontSize: "1.2rem" }}>
                    {new Intl.NumberFormat("ro-RO").format(
                      currentTotalVindecati
                    )}
                  </strong>
                  {props.lang === "ro" ? " vindecări" : " recovered"}
                </Typography>
                <Typography
                  className={`${classes.greenBottom} ${classes.infoBubble}`}
                  variant="h6"
                >
                  <strong style={{ fontSize: "1.2rem" }}>
                    {new Intl.NumberFormat("ro-RO").format(dailyTotalVindecati)}
                  </strong>
                  {props.lang === "ro" ? " vindecări noi" : " new recoveries"}
                </Typography>
                <Typography
                  className={`${classes.yellowBottom} ${classes.infoBubble}`}
                  variant="h6"
                >
                  <strong style={{ fontSize: "1.2rem" }}>
                    {new Intl.NumberFormat("ro-RO").format(
                      currentTotal - currentTotalDead - currentTotalVindecati
                    )}
                  </strong>
                  {props.lang === "ro" ? " cazuri active" : " active cases"}
                </Typography>
              </Fragment>
            )}
            {clicked === "None" && (
              <Typography
                className={`${classes.orangeBottom} ${classes.infoBubble}`}
                variant="h6"
              >
                <strong style={{ fontSize: "1.2rem" }}>
                  {new Intl.NumberFormat("ro-RO").format(currentAti)}
                </strong>
                {props.lang === "ro" ? " ATI" : " ICU"}
              </Typography>
            )}
          </div>
        </Container>
        <div className="notranslate">
          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 20,
              marginTop: 30,
              textAlign: "left",
            }}
            variant="subtitle1"
          >
            {props.lang === "ro" ? (
              <span>
                <strong>Grafic: </strong>cazuri și decese în ultima perioadă{" "}
                <span>
                  {clicked !== "None"
                    ? "în județul " + judNames[clicked]
                    : null}
                </span>{" "}
                (date zilnice)
              </span>
            ) : (
              <span>
                <strong>Chart: </strong>cases and deaths{" "}
                <span>
                  {clicked !== "None"
                    ? "in " + judNames[clicked] + " county"
                    : null}
                </span>
                (daily data)
              </span>
            )}
          </Typography>
          <DailyChart clicked={clicked} data={roLineData} lang={props.lang} />

          <Typography
            style={{
              color: "grey",
              lineHeight: "18px",
              marginTop: 10,
              marginBottom: 0,
              textAlign: "left",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro"
              ? "NOTĂ: linia roșie (cazuri) folosește scara din partea stângă; linia neagră (decese) folosește scara din partea dreaptă"
              : "NOTE: the red line (cases) uses the scale on the left; the black line (deaths) uses the scale on the right"}
          </Typography>
        </div>
        <Divider style={{ marginTop: 50, marginBottom: 40 }} />
        <Container
          style={{
            maxWidth: "800px",
            width: "100%",
            textAlign: "left",
            padding: 0,
            marginTop: 20,
          }}
        >
          <Typography style={{ marginBottom: 10, marginTop: 20 }} variant="h5">
            <strong>
              {props.lang === "ro"
                ? "Vaccinuri efectuate - cele mai noi date naționale"
                : "Vaccines performed - most recent national data"}
            </strong>
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 20,
              marginTop: 5,
              color: "grey",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro" ? "Actualizat: " : "Updated: "}{" "}
            {updateTime.vaccin}
          </Typography>

          <Typography
            className={`${classes.greenBottom} ${classes.infoBubble}`}
            variant="h6"
          >
            <strong style={{ fontSize: "1.2rem" }}>
              {new Intl.NumberFormat("ro-RO").format(
                vaccinData[vaccinData.length - 1] &&
                  vaccinData[vaccinData.length - 1].total
              )}
            </strong>{" "}
            {props.lang === "ro" ? "doze (total)" : "doses (total)"}
          </Typography>
          <Typography
            className={`${classes.greenBottom} ${classes.infoBubble}`}
            variant="h6"
          >
            <strong style={{ fontSize: "1.2rem" }}>
              {vaccinData[0] &&
                new Intl.NumberFormat("ro-RO").format(
                  vaccinData[vaccinData.length - 1].per_day
                )}
            </strong>{" "}
            {props.lang === "ro" ? "doze noi" : "new doses"}
          </Typography>

          <Typography
            className="notranslate"
            style={{ fontWeight: 700, marginTop: 30 }}
            variant="subtitle1"
          >
            {props.lang === "ro" ? "Alege un grafic:" : "Select a map"}
          </Typography>

          <FormControl component="fieldset">
            <RadioGroup
              className="notranslate"
              style={{
                width: "auto",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                marginTop: 0,
              }}
              // aria-label="gender"
              name="vax1"
              value={vaxChart}
              onChange={(event) => setVaxChart(event.target.value)}
            >
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "toate datele" : "all data"}
                  </div>
                }
              />
              <FormControlLabel
                value="month"
                control={<Radio />}
                label={
                  <div style={{ fontSize: 15 }}>
                    {props.lang === "ro" ? "ultima lună" : "last month"}
                  </div>
                }
              />
            </RadioGroup>
          </FormControl>

          <Fragment>
            <Typography
              style={{ fontWeight: 400, marginBottom: 20, marginTop: 10 }}
              variant="subtitle1"
            >
              {props.lang === "ro" ? (
                <span>
                  <strong>Grafic: </strong>numărul zilnic de vaccinuri făcute la
                  nivel național.
                </span>
              ) : (
                <span>
                  <strong>Chart: </strong>
                  the number of daily vaccinations performed at the national
                  level.
                </span>
              )}
            </Typography>
            {vaxChart == "all" && (
              <VaccinBar
                data={vaccinData}
                lang={props.lang}
                color="rgb(86, 159, 57)"
              />
            )}

            {vaxChart == "month" && (
              <VaccMonth
                data={vaccinData}
                lang={props.lang}
                color="rgb(86, 159, 57)"
              />
            )}

            <div style={{ marginTop: 20 }}>
              <LegendMy
                style={{ marginTop: 0, marginBottom: 0 }}
                content={[
                  {
                    name: props.lang === "ro" ? "Pfizer (1)" : "Pfizer (1)",
                    color: "#16a085",
                  },
                  {
                    name: props.lang === "ro" ? "Pfizer (2)" : "Pfizer (2)",
                    color: "#1abc9c",
                  },

                  {
                    name: props.lang === "ro" ? "Moderna (1)" : "Moderna (1)",
                    color: "#f39c12",
                  },
                  {
                    name: props.lang === "ro" ? "Moderna (2)" : "Moderna (2)",
                    color: "#f1c40f",
                  },

                  {
                    name:
                      props.lang === "ro"
                        ? "Astra Zeneca (1)"
                        : "Astra Zeneca (1)",
                    color: "#8e44ad",
                  },
                  {
                    name:
                      props.lang === "ro"
                        ? "Astra Zeneca (2)"
                        : "Astra Zeneca (2)",
                    color: "#9b59b6",
                  },
                  {
                    name:
                      props.lang === "ro"
                        ? "Johnson & Johnson"
                        : "Johnson & Johnson",
                    color: "#3498db",
                  },
                  {
                    name: props.lang === "ro" ? "Pfizer (3)" : "Pfizer (3)",
                    color: "rgba(26,188,156,0.4)",
                  },
                  {
                    name: props.lang === "ro" ? "Moderna (3)" : "Moderna (3)",
                    color: "rgba(241,196,15,0.4)",
                  },
                ]}
              />
            </div>
          </Fragment>
        </Container>
        {/* <Divider style={{ marginTop: 50, marginBottom: 40 }} />
        <Container
          style={{
            maxWidth: "800px",
            width: "100%",
            textAlign: "left",
            padding: 0,
            marginTop: 20,
          }}
        >
          <Typography style={{ marginBottom: 10, marginTop: 20 }} variant="h5">
            <strong>
              {props.lang === "ro" ? "Variante de virus" : "Virus variants"}
            </strong>
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 20,
              marginTop: 5,
              color: "grey",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro" ? "Actualizat: " : "Updated: "}{" "}
            {updateTime.main}
          </Typography>

          <Fragment>
            <Typography
              style={{ fontWeight: 400, marginBottom: 20, marginTop: 30 }}
              variant="subtitle1"
            >
              {props.lang === "ro" ? (
                <span>
                  <strong>Grafic: </strong>evoluția variantelor de SARS-CoV-2 în
                  România (date săptămânale)
                </span>
              ) : (
                <span>
                  <strong>Chart: </strong>
                  the evolution of SARS-CoV-2 variants in Romania (weekly data)
                </span>
              )}
            </Typography>

            <VariantsBar data={variantData} />
            {variantData.length > 0 && (
              <div style={{ marginTop: 20 }}>
                <LegendMy
                  style={{ marginTop: 0, marginBottom: 0 }}
                  content={[
                    {
                      name: variantData[0].v_name_0,
                      color: "#a6cee3",
                    },
                    {
                      name: variantData[0].v_name_1,
                      color: "#1f78b4",
                    },
                    {
                      name: variantData[0].v_name_2,
                      color: "#b2df8a",
                    },
                    {
                      name: variantData[0].v_name_3,
                      color: "#33a02c",
                    },
                    {
                      name: variantData[0].v_name_4,
                      color: "#fb9a99",
                    },
                    {
                      name: variantData[0].v_name_5,
                      color: "#e31a1c",
                    },
                    {
                      name: variantData[0].v_name_6,
                      color: "#fdbf6f",
                    },
                    {
                      name: variantData[0].v_name_7,
                      color: "#ff7f00",
                    },
                    {
                      name: variantData[0].v_name_8,
                      color: "#cab2d6",
                    },
                    {
                      name: variantData[0].v_name_9,
                      color: "#cbcbcb",
                    },
                    {
                      name: variantData[0].v_name_10,
                      color: "#6a3d9a",
                    },
                  ]}
                />
              </div>
            )}
          </Fragment>
        </Container> */}
      </Container>

      <Container style={{ maxWidth: 800, textAlign: "left" }}>
        {/* <Typography
          style={{ color: "#333", marginTop: 10 }}
          target="_blank"
          variant="subtitle2"
          component="a"
          href="https://docs.google.com/spreadsheets/d/1TafrOhj0AivTvTgNUGett6gEzn7m4mO2Urr-xYaIY4k/edit?usp=sharing"
        >
          {props.lang === "ro"
            ? "Vezi datele complete (open data)"
            : "View complete dataset (open data)"}
        </Typography> */}
        <Divider style={{ marginTop: 40, marginBottom: 40 }} />
        {/* 
        <JustArticles
          clicked={clicked}
          setClicked={setClicked}
          lang={props.lang}
        />

        <Divider style={{ marginTop: 40, marginBottom: 40 }} /> */}

        <Typography
          style={{ marginTop: 20, marginBottom: 10, color: "grey" }}
          variant="subtitle2"
        >
          <strong> {props.lang === "ro" ? "Surse:" : "Sources"}</strong>
        </Typography>
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="http://www.ms.ro/comunicate/"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          {props.lang === "ro"
            ? "1. Ministerul sănătății"
            : "1. Romanian Health Ministry"}
        </Typography>

        <br />
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="http://www.dsu.mai.gov.ro/"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          {props.lang === "ro"
            ? "2. Departamentul pentru Situații de Urgență"
            : "2. Romanian Department For Emergency Situations"}
        </Typography>

        <Typography
          style={{ marginTop: 0, color: "grey", textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          {props.lang === "ro"
            ? "3. Monitorizarea presei"
            : "3. Press monitoring"}
        </Typography>
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="https://www.ecdc.europa.eu/en/novel-coronavirus-china"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          4. European Centre for Disease Prevention and Control
        </Typography>
        <Divider style={{ marginBottom: 40, marginTop: 40 }} />

        <div style={{ textAlign: "center", marginBottom: 40 }}>
          <Typography variant="subtitle2">Tech by</Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://addictad.ro"
          >
            <img
              style={{ height: 16, marginTop: 5 }}
              src="/images/logo-400px.png"
              alt=""
            />
          </a>
        </div>
      </Container>
    </div>
  );
}

export default RoMap;
