import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import Refresh from "@material-ui/icons/Refresh";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import "./App.css";
import ChipMenu from "./components/ChipMenu";
import Header from "./components/common/Header";
import LegendMyBig from "./components/LegendMyBig";
import CaseMapRo from "./components/romania/CaseMapRo";
import DailyChartBook from "./components/romania/DailyChartBook";
import DeadMapRo from "./components/romania/DeadMapRo";
import RoLine from "./components/romania/RoLine";
import VaccinBar from "./components/romania/VaccinBar";
import VariantsBar from "./components/romania/VariantsBar";
import VaccMonth from "./components/romania/VaccMonth";
import DeceseMap from "./components/vaccin/DeceseMap";
import updateTime from "./data/updated.json";

import judgps from "./sources/judgps.json";
import judNames from "./sources/judNames.json";
import timelineMarks from "./sources/timelineMarks.json";
import timelineMarksEn from "./sources/timelineMarksEn.json";
// import updateTime from "./data/updated.json";
const useStyles = makeStyles({
  infoBubble: {
    display: "inline-block",
    marginTop: 4,
    marginBottom: 4,
    marginRight: 10,
    width: window.innerWidth > 500 ? "25%" : "47%",
    fontSize: 16,
    paddingTop: 2,
    paddingBottom: 2,
    opacity: 0.9,
    textAlign: "left",
  },
  redBottom: {
    borderBottom: "4px solid #dd1a18",
  },
  yellowBottom: {
    borderBottom: "4px solid #FFC107",
  },
  greenBottom: {
    borderBottom: "4px solid rgb(86, 159, 57)",
  },
  blueBottom: {
    borderBottom: "4px solid rgb(60, 153, 220)",
  },
  blackBottom: {
    borderBottom: "4px solid #333",
  },
  orangeBottom: {
    borderBottom: "4px solid #e67e22",
  },
});

function RoMap(props) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  const classes = useStyles();
  // per judet: cazuri_toal, decese_total, judet, lat, long
  const [cazuri, setCazuri] = useState([]);
  const [roLineData, setRoLineData] = useState([]);
  const [allData, setAllData] = useState([]);

  // toate cazurile de toate tipurile

  const [currentTotal, setCurrentTotal] = useState(0);
  const [currentAti, setCurrentAti] = useState(0);
  const [dailyTotal, setDailyTotal] = useState(0);
  const [currentTotalDead, setCurrentTotalDead] = useState(0);
  const [dailyTotalDead, setDailyTotalDead] = useState(0);
  const [currentTotalVindecati, setCurrentTotalVindecati] = useState(0);
  const [dailyTotalVindecati, setDailyTotalVindecati] = useState(0);
  const [selectedMap, setSelectedMap] = useState("decese");
  const [selectedChart, setSelectedChart] = useState("daily");
  const [vaxChart, setVaxChart] = useState("month");
  const [clicked, setClicked] = useState("None");
  const [mapSwitch, setMapSwitch] = useState(false);
  const [chartSwitch, setChartSwitch] = useState(false);
  const [daySwitch, setDaySwitch] = useState(0);
  const [vaccinData, setvaccinData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const getVaxData = async () => {
    await fetch(`/data/vaccin.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        data.forEach(
          (itm) =>
            (itm.dataOk = moment(itm.data, "MM/DD/YYYY").format("DD/MM 'YY"))
        );

        setvaccinData(data);
      });
    await fetch(`/data/variants_ro.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        data.forEach(
          (itm) => (itm.date = moment(itm.week, "YYYY-WW").format("MMM-DD"))
        );
        setVariantData(data);
      });
  };
  useEffect(() => {
    getVaxData();
  }, []);
  // on page load -> cazuri, allCases, updated, updatedTeste, teste
  useEffect(() => {
    let getData = async () => {
      let localCopy;
      await fetch(`/data/dailyCalc.json`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json();
        })
        .then(
          (data) =>
            (localCopy = data
              .filter((itm) => itm.judet !== "necomunicat")
              .sort((a, b) => (a.data_unix > b.data_unix ? -1 : 1)))
        );

      let firstTenDays = [];
      let caseNoTotal = [];

      localCopy.forEach((itm) => {
        firstTenDays.push(itm);
        itm.judet === "total" && caseNoTotal.push(itm);
      });

      caseNoTotal = caseNoTotal.sort((a, b) =>
        a.data_unix > b.data_unix ? -1 : 1
      );

      setAllData(firstTenDays);
      // setRoLineData(caseNoTotal);
      // setCurrentAti(caseNoTotal[caseNoTotal.length - 1].ati);
      // setCurrentTotal(caseNoTotal[caseNoTotal.length - 1].cazuriTotal);
      // setCurrentTotalDead(caseNoTotal[caseNoTotal.length - 1].deceseTotal);
      // setCurrentTotalVindecati(caseNoTotal[caseNoTotal.length - 1].vindTotal);
      // setDailyTotal(caseNoTotal[caseNoTotal.length - 1].cazuriAzi);
      // setDailyTotalDead(caseNoTotal[caseNoTotal.length - 1].deceseAzi);
      // setDailyTotalVindecati(caseNoTotal[caseNoTotal.length - 1].vindAzi);
    };

    getData();
  }, []);

  useEffect(() => {
    let caseNoPerJud = [];
    allData
      .slice(-1 * daySwitch * 43, (-1 * daySwitch + 1) * 43)
      .filter((itm) => itm.judet !== "total" && itm.judet !== "ro-if")
      .forEach((el) => {
        if (el.judet !== "necunoscut") {
          let obj = {
            judet: el.judet,
            lat: judgps.filter((i) => i.judet === el.judet)[0].lat,
            long: judgps.filter((i) => i.judet === el.judet)[0].long,
            cazuri_azi: el.cazuriAzi,
            decese_azi: el.deceseAzi,
            data: el.data,
          };
          if (mapSwitch) {
            obj.cazuri_total = Math.round(
              el.cazuriTotal /
                judgps.filter((itm) => itm.judet === obj.judet)[0].pop_100k
            );
            obj.decese_total = (
              el.deceseTotal /
              judgps.filter((itm) => itm.judet === obj.judet)[0].pop_100k
            ).toFixed(1);
          } else {
            obj.cazuri_total = el.cazuriTotal;
            obj.decese_total = el.deceseTotal;
          }
          caseNoPerJud.push(obj);
        }
      });

    setCazuri(caseNoPerJud);
  }, [mapSwitch, allData, daySwitch]);

  useEffect(() => {
    let caseNoTotal = [];
    let ldata = [];
    ldata = allData;
    if (clicked !== "None") {
      ldata &&
        ldata.forEach((itm) => {
          itm.judet === clicked && caseNoTotal.push(itm);
        });
    } else {
      ldata &&
        ldata.forEach((itm) => {
          itm.judet === "total" && caseNoTotal.push(itm);
        });
    }
    caseNoTotal = caseNoTotal.sort((a, b) => {
      if (a.data_unix > b.data_unix) {
        return -1;
      } else {
        return 1;
      }
    });

    if (caseNoTotal.length > 0) {
      setRoLineData(caseNoTotal);
      setCurrentAti(caseNoTotal[0].ati);
      setCurrentTotal(caseNoTotal[0].cazuriTotal);
      setCurrentTotalDead(caseNoTotal[0].deceseTotal);
      setCurrentTotalVindecati(caseNoTotal[0].vindTotal);
      setDailyTotal(caseNoTotal[0].cazuriAzi);
      setDailyTotalDead(caseNoTotal[0].deceseAzi);
      setDailyTotalVindecati(caseNoTotal[0].vindAzi);
    }
    clicked !== "None" &&
      ReactGA.event({
        category: "Romania",
        action: clicked,
      });
  }, [clicked, allData]);

  useEffect(() => {
    let caseNoPerJud = [];
    allData
      .slice(0, 43)
      .filter((itm) => itm.judet !== "total" && itm.judet !== "ro-if")
      .forEach((el) => {
        let obj = {
          judet: el.judet,
          lat: judgps.filter((i) => i.judet === el.judet)[0].lat,
          long: judgps.filter((i) => i.judet === el.judet)[0].long,
          cazuri_azi: el.cazuriAzi,
          decese_azi: el.deceseAzi,
          data: el.data,
        };
        if (mapSwitch) {
          obj.cazuri_total = Math.round(
            el.cazuriTotal /
              judgps.filter((itm) => itm.judet === obj.judet)[0].pop_100k
          );
          obj.decese_total = (
            el.deceseTotal /
            judgps.filter((itm) => itm.judet === obj.judet)[0].pop_100k
          ).toFixed(1);
        } else {
          obj.cazuri_total = el.cazuriTotal;
          obj.decese_total = el.deceseTotal;
          obj.cazuri_azi = el.cazuriAzi;
          obj.decese_azi = el.deceseAzi;
          // obj.decese_azi = el.deceseAzi
        }
        caseNoPerJud.push(obj);
      });
    setCazuri(caseNoPerJud);
  }, [mapSwitch, allData]);

  const handleSlider = (event, newValue) => {
    setDaySwitch(newValue);
  };
  // console.log(variantData[0]);
  return (
    <div
      style={{
        paddingLeft: 40,
        paddingRight: 40,
        paddinTop: 20,
        paddingBottom: 20,
      }}
    >
      <div
        className="notranslate"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "left",
          marginBottom: 0,
        }}
      >
        <FormControl
          style={{ marginBottom: 0, marginTop: 10, marginRight: 10 }}
        >
          <Select
            style={{ fontSize: "6rem", fontWeight: 700, borderBottom: 0 }}
            labelId="jduet-label"
            disableUnderline
            color="secondary"
            id="jduet-select"
            value={clicked}
            onChange={(event) => setClicked(event.target.value)}
          >
            <MenuItem key="None" value="None">
              {props.lang === "ro" ? (
                <strong>Toată țara</strong>
              ) : (
                <strong>National data</strong>
              )}
            </MenuItem>
            {Object.keys(judNames).map(
              (j) =>
                j !== "ro-if" && (
                  <MenuItem key={j} value={j}>
                    {judNames[j]} {props.lang === "ro" ? null : "county"}
                  </MenuItem>
                )
            )}
          </Select>
        </FormControl>
      </div>

      <div className="notranslate" style={{ marginTop: 0 }}>
        <DailyChartBook clicked={clicked} data={roLineData} lang={props.lang} />
      </div>

      <Divider style={{ marginBottom: 40, marginTop: 20 }} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxHeight: "90px", alignSelf: "center", marginRight: 20 }}
            src="/images/hortensia-2210.png"
            alt=""
            onClick={() => window.location.reload()}
          />
          <div>
            <img
              style={{ width: 400, alignSelf: "center" }}
              src="/images/Monitorizare-Coronavirus.png"
              alt=""
            />{" "}
            <Typography
              variant="h5"
              style={{ textAlign: "center", marginTop: 10 }}
            >
              coronavirus.casajurnalistului.ro
            </Typography>
          </div>
        </div>
        <div style={{ opacity: 0.4 }}>
          <Typography
            variant="body2"
            style={{ textAlign: "right", fontWeight: "bold", fontSize: 20 }}
          >
            Surse:
          </Typography>
          <Typography
            variant="body2"
            style={{ textAlign: "right", fontSize: 20 }}
          >
            stirioficiale.ro, data.gov.ro, datelazi.ro, mai.gov.ro
          </Typography>
        </div>
      </div>

      {/* <Divider style={{ marginBottom: 30, marginTop: 20 }} /> */}
    </div>
  );
}

export default RoMap;
