import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function ChartBarTeste(props) {
  const [localData, setLocalData] = useState();
  const [localClicked, setLocalClicked] = useState();
  useEffect(() => {
    let localColl = props.data;
    setLocalData(localColl);
    setLocalClicked(props.clicked);
  }, [props]);
  // console.log(localData);
  return (
    <div
      style={{
        marginLeft: -30,
        height: window.innerWidth > 500 ? 300 : 300,
        display: "flex",
        justifyContent: "left",
      }}
    >
      <ResponsiveContainer
        style={{ marginLeft: -30 }}
        width="100%"
        height="100%"
      >
        <BarChart width="100%" height={300} data={localData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={{ fontSize: 12 }} dataKey="data" tickMargin={5} />
          <YAxis
            domain={[0, "dataMax"]}
            tick={{ fontSize: 12 }}
            tickMargin={5}
          />
          <Tooltip itemStyle={{ fontSize: 14 }} />
          <ReferenceLine y={0} stroke="#cbcbcb" />
          <Bar
            dataKey="cazuriAzi"
            name={props.lang === "ro" ? "cazuri noi" : "new cases"}
            fill="Crimson"
            isAnimationActive={false}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
