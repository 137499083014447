import moment from "moment";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export default function ChartBarTeste(props) {
  const [localState, setLocalState] = React.useState(props.data);
  React.useEffect(() => {
    let l_data = [...props.data];
    l_data.forEach(
      (itm) => (itm.dataOk = moment(itm.data, "YYYY-MM-DD").format("DD/MM 'YY"))
    );
    setLocalState(l_data);
  }, [props]);
  return (
    <div
      style={{
        marginLeft: 0,

        width: "100%",
        display: "flex",
        justifyContent: "left",
      }}
    >
      <ResponsiveContainer
        style={{ marginLeft: -30 }}
        width="100%"
        height={window.innerWidth > 500 ? 300 : 300}
      >
        <BarChart
          width="100%"
          // height={300}
          data={props.data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={{ fontSize: 12 }} dataKey="dataOk" tickMargin={5} />
          <YAxis
            domain={[0, "dataMax"]}
            tick={{ fontSize: 12 }}
            tickMargin={5}
          />
          <Tooltip itemStyle={{ fontSize: 14 }} isAnimationActive={false} />
          <Bar
            dataKey="decese"
            name={props.lang === "ro" ? "decese" : "deaths"}
            fill={"#333"}
            isAnimationActive={false}
            barSize={window.innerWidth < 500 ? 15 : 40}
            stackId="a"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
