import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const days = [6, 5, 4, 3, 2, 1, 0];
const defaultHist = [];
days.forEach((day) => {
  defaultHist.push({
    name: moment().subtract(day, "days").format("DD/MM"),
    articole: 0,
    order: day,
  });
});

export default function LineChartComponent(props) {
  const [localData, setLocalData] = useState([]);
  const [localClicked, setLocalClicked] = useState([]);
  useEffect(() => {
    let lc = props.data;
    props.clicked === "None" &&
      lc.forEach((itm) => {
        itm.cazuriActive = itm.cazuriTotal - itm.deceseTotal - itm.vindTotal;
      });
    setLocalData(lc);
    setLocalClicked(props.clicked);
  }, [props]);
  return (
    <div
      style={{
        marginLeft: -20,
        height: window.innerWidth > 500 ? 300 : 300,
        display: "flex",
        justifyContent: "left",
      }}
    >
      <ResponsiveContainer
        style={{ marginLeft: -20 }}
        width="100%"
        height="100%"
      >
        <LineChart
          key={Math.random()}
          data={
            localData
              ? localData.sort((a, b) => (a.data_unix < b.data_unix ? -1 : 1))
              : []
          }
          margin={{ top: 10, right: 10, bottom: 10, left: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip itemStyle={{ fontSize: 14 }} isAnimationActive={false} />
          <XAxis
            hide={false}
            dataKey="data"
            tickMargin={10}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            hide={false}
            type="number"
            tick={{ fontSize: 12 }}
            domain={[0, "dataMax"]}
            tickMargin={10}
          />
          <Line
            dot={false}
            key={Math.random()}
            isAnimationActive={false}
            type="monotone"
            dataKey="cazuriTotal"
            name={props.lang === "ro" ? "cazuri" : "cases"}
            stroke="Crimson"
            strokeWidth={2}
            activeDot={{ r: 8 }}
          />
          {localClicked === "None" && (
            <Line
              dot={false}
              key={Math.random()}
              isAnimationActive={false}
              type="monotone"
              dataKey="cazuriActive"
              name={props.lang === "ro" ? "cazuri active" : "active cases"}
              stroke="#FFC107"
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          )}
          {localClicked === "None" && (
            <Line
              dot={false}
              key={Math.random()}
              isAnimationActive={false}
              type="monotone"
              dataKey="deceseTotal"
              name={props.lang === "ro" ? "decese" : "deaths"}
              stroke="black"
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          )}
          {localClicked === "None" && (
            <Line
              dot={false}
              key={Math.random()}
              isAnimationActive={false}
              type="monotone"
              dataKey="vindTotal"
              name={props.lang === "ro" ? "vindecări" : "recoveries"}
              stroke="#569f39"
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
