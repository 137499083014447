import moment from "moment";
import React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import teste_amenzi_apeluri from "../../data/teste_amenzi_apeluri.json";

teste_amenzi_apeluri.forEach(
  (itm) => (itm.dataOk = moment(itm.data, "MM/DD/YYYY").format("DD/MM 'YY"))
);

export default function ChartBarTeste(props) {
  // console.log(props);
  const [localData, setLocalData] = React.useState([]);
  React.useEffect(() => {
    let l_data = [...props.data];
    l_data.forEach(
      (itm) => (itm.dataOk = moment(itm.data, "YYYY-MM-DD").format("DD/MM 'YY"))
    );
    setLocalData(l_data);
  }, [props]);
  return (
    <div
      style={{
        marginLeft: -10,
        height: window.innerWidth > 500 ? 300 : 300,
        display: "flex",
        justifyContent: "left",
      }}
    >
      <ResponsiveContainer
        style={{ marginLeft: -20 }}
        width="100%"
        height="100%"
      >
        <LineChart width="100%" height={300} data={localData || []}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tick={{ fontSize: 12 }} dataKey="dataOk" tickMargin={5} />
          <YAxis
            allowDataOverflow={true}
            // domain={[0, 45000]}
            tick={{ fontSize: 12 }}
            tickMargin={5}
            tickFormatter={(tick) =>
              Intl.NumberFormat().format(tick.toFixed(1))
            }
          />
          <Tooltip
            itemStyle={{ fontSize: 14 }}
            isAnimationActive={false}
            formatter={(lbl) => {
              return Intl.NumberFormat().format(lbl);
            }}
          />
          {/* <Bar
            dataKey="ati"
            name={props.lang === "ro" ? "teste pe zi" : "tests per day"}
            fill={props.color}
            isAnimationActive={false}
            barSize={window.innerWidth < 500 ? 15 : 28}
            stackId="a"
          /> */}
          <Line
            dot={false}
            // key={Math.random()}
            isAnimationActive={false}
            type="monotone"
            dataKey="ati"
            name={props.lang === "ro" ? "paturi ATI ocupate" : "used ICU beds"}
            stroke="Crimson"
            strokeWidth={2}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
