import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import Refresh from "@material-ui/icons/Refresh";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import "./App.css";
import ChipMenu from "./components/ChipMenu";
import Header from "./components/common/Header";
import AtiBar from "./components/vaccin/AtiBar";
import AtiMap from "./components/vaccin/AtiMap";
import updateTime from "./data/updated.json";
import judgps from "./sources/judgps.json";
import judNames from "./sources/judNames.json";
import timelineMarksEnShort from "./sources/timelineMarksEnShort.json";
import timelineMarksShort from "./sources/timelineMarksShort.json";

function Vaccinare(props) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const [cazuri, setCazuri] = useState([]);

  const [roLineData, setRoLineData] = useState([]);

  const [clicked, setClicked] = useState("None");
  const [daySwitch, setDaySwitch] = useState(0);
  const [dataPj, setDataPj] = useState([]);

  const getData = async () => {
    await fetch(`/data/open_data_pj.json`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        data.forEach((itm) => {
          itm.judet = itm.jud;
          if (itm.jud !== "total") {
            itm.lat = judgps.find((i) => i.judet === itm.jud).lat;
            itm.long = judgps.find((i) => i.judet === itm.jud).long;
          } else {
            itm.lat = 0;
            itm.long = 0;
          }
        });
        setDataPj(data);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let lvacc = [...dataPj];
    let coll = lvacc
      .sort((a, b) => (a.data_unix < b.data_unix ? 1 : -1))
      .slice(-1 * daySwitch * 42, (-1 * daySwitch + 1) * 42);

    setCazuri(coll);
  }, [daySwitch, dataPj]);

  useEffect(() => {
    let caseNoTotal = [];
    let ldata = [];
    ldata = [...dataPj];

    if (clicked !== "None") {
      ldata &&
        ldata.forEach((itm) => {
          itm.judet === clicked && caseNoTotal.push(itm);
        });
    } else {
      ldata &&
        ldata.forEach((itm) => {
          itm.judet === "total" && caseNoTotal.push(itm);
        });
    }
    caseNoTotal = caseNoTotal.sort((a, b) => {
      if (a.data_unix < b.data_unix) {
        return -1;
      } else {
        return 1;
      }
    });

    if (caseNoTotal.length > 0) {
      setRoLineData(caseNoTotal);
    }

    clicked !== "None" &&
      ReactGA.event({
        category: "Romania",
        action: clicked,
      });
  }, [clicked, dataPj]);

  const handleSlider = (event, newValue) => {
    setDaySwitch(newValue);
  };

  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <Typography
        variant="body2"
        style={{
          fontSize: 12,
          backgroundColor: "#dd1b14",
          color: "white",
          padding: 4,
          width: "100%",
        }}
      >
        {props.lang === "ro"
          ? "Această aplicație folosește cookies pentru a colecta statistici de utilizare. Prin folosirea aplicației îți exprimi acordul cu "
          : "This application uses cookies to collect usage statistics. By using the application you accept "}
        <Link
          style={{ color: "white", margin: 0 }}
          to={props.lang === "ro" ? "/cookies/" : "/en/cookies/"}
        >
          {props.lang === "ro"
            ? "politica noastră privind datele personale"
            : "our personal data policy"}
        </Link>
        .
      </Typography>
      <Container style={{ maxWidth: "800px" }}>
        <Header />
        <ChipMenu active={"ati"} lang={props.lang} />

        <Container
          style={{
            textAlign: "left",
            padding: 0,
            marginTop: 20,
          }}
        >
          <Typography style={{ marginBottom: 10, marginTop: 30 }} variant="h5">
            <strong>
              {props.lang === "ro"
                ? "Ocupare ATI - date complete"
                : "ICU bed use - complete data"}
            </strong>
          </Typography>
          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 20,
              marginTop: 5,
              color: "grey",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro" ? "Actualizat: " : "Updated: "}{" "}
            {updateTime.open_gov}
          </Typography>

          {/* <Typography style={{ marginTop: 20 }} variant="subtitle1">
            {props.lang === "ro" ? (
              <span>
                <strong>NOTĂ:</strong> din data de 11 iunie, autoritățile nu mai
                oferă date despre numărul de decese per județ
              </span>
            ) : (
              <span>
                <strong>NOTE:</strong> on 11.06.2020, the authorities stopped
                providing data on the number of deaths per county
              </span>
            )}
          </Typography> */}

          <div>
            <Typography
              style={{ fontWeight: 400, marginTop: 0, marginBottom: 10 }}
              variant="subtitle1"
            >
              {props.lang === "ro" ? (
                <strong>Harta curentă: </strong>
              ) : (
                <strong>Current map: </strong>
              )}
              {props.lang === "ro"
                ? "numărul și distribuția paturilor ATI ocupate în România."
                : "number and distribution of ICU beds in use in Romania."}
            </Typography>
          </div>

          <Typography
            style={{ fontWeight: 400, marginTop: 0, marginBottom: 20 }}
            variant="subtitle1"
          ></Typography>

          <div style={{ width: "108%", marginLeft: "-4%" }}>
            <div>
              <AtiMap
                setClicked={setClicked}
                cazuri={cazuri}
                lang={props.lang}
              />
            </div>
          </div>
        </Container>

        {/* -------------- */}
        {/* <Typography variant = "h5" style={{marginBottom: 70}}>
          {moment(moment().subtract(1, 'days').startOf('day')).add(daySwitch, 'days').format('DD / MM / YYYY')}
        </Typography> */}
        <div style={{ marginTop: 0, marginBottom: 20 }}>
          <strong
            style={{
              padding: 4,
              paddingRight: 10,
              paddingLeft: 10,
              borderRadius: 4,
              backgroundColor: "Crimson",
              color: "white",
              marginTop: 10,
            }}
          >
            {moment(cazuri[0] && cazuri[0].data).format("DD/MM 'YY")}
          </strong>
        </div>
        {/* -------------- */}
        <Typography
          style={{ fontWeight: 400, marginTop: 0, textAlign: "left" }}
          variant="subtitle1"
        >
          {props.lang === "ro" ? (
            <span>
              <strong>Alege o zi</strong> pentru a o afișa pe hartă:
            </span>
          ) : (
            <span>
              <strong>Select a day </strong> to display it on the map:
            </span>
          )}
        </Typography>
        <div
          style={{
            marginTop: 10,
            marginBottom: 0,
            paddingLeft: 10,
            paddingRight: 10,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Slider
            value={daySwitch}
            onChange={handleSlider}
            track="inverted"
            defaultValue={0}
            aria-labelledby="discrete-slider"
            step={1}
            marks={
              props.lang === "ro" ? timelineMarksShort : timelineMarksEnShort
            }
            min={-28}
            max={0}
            color="secondary"
            style={{ width: "100%" }}
          />
        </div>
        <Divider style={{ marginBottom: 50, marginTop: 40 }} />
        <Container
          style={{
            maxWidth: "800px",
            textAlign: "left",
            padding: 0,
            marginTop: 20,
          }}
        >
          <div
            className="notranslate"
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "left",
              marginBottom: 0,
            }}
          >
            <FormControl
              style={{ marginBottom: 20, marginTop: 0, marginRight: 10 }}
            >
              <Select
                style={{ fontSize: "1.5rem", fontWeight: 700, borderBottom: 0 }}
                labelId="jduet-label"
                disableUnderline
                color="secondary"
                id="jduet-select"
                value={clicked}
                onChange={(event) => setClicked(event.target.value)}
              >
                <MenuItem key="None" value="None">
                  {props.lang === "ro" ? (
                    <strong>Toată țara</strong>
                  ) : (
                    <strong>National data</strong>
                  )}
                </MenuItem>
                {Object.keys(judNames).map(
                  (j) =>
                    j !== "ro-if" && (
                      <MenuItem key={j} value={j}>
                        {judNames[j]} {props.lang === "ro" ? null : "county"}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
            {clicked !== "None" ? (
              <Chip
                onClick={() => setClicked("None")}
                style={{
                  backgroundColor: "Crimson",
                  color: "white",
                  marginBottom: 20,
                  marginLeft: 0,
                  border: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
                label={<strong>RESET</strong>}
                icon={<Refresh style={{ color: "white" }} />}
              />
            ) : (
              <Chip
                style={{
                  color: "#555",
                  marginBottom: 20,
                  marginLeft: 0,
                  border: 0,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
                label={
                  <strong>
                    {props.lang === "ro" ? (
                      <strong>ALEGE JUDEȚ</strong>
                    ) : (
                      <strong>SELECT COUNTY</strong>
                    )}
                  </strong>
                }
              />
            )}
          </div>

          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 10,
              marginTop: 0,
              color: "grey",
            }}
            variant="subtitle2"
          >
            {props.lang === "ro" ? "Actualizat: " : "Updated: "}
            {updateTime.open_gov}
          </Typography>

          <Typography
            style={{
              fontWeight: 400,
              marginBottom: 20,
              marginTop: 0,
              //   color: "grey",
            }}
            variant="body1"
          >
            {props.lang === "ro"
              ? "Date zilnice totale pentru județul selectat."
              : "Total daily data for the selected county."}
          </Typography>

          <div className="notranslate">
            <Typography
              style={{ fontWeight: 400, marginBottom: 20, marginTop: 10 }}
              variant="subtitle1"
            >
              {props.lang === "ro" ? (
                <span>
                  <strong>Grafic: </strong>paturi ATI ocupate în județul
                  selectat
                </span>
              ) : (
                <span>
                  <strong>Chart: </strong>
                  ICU beds in use in the selected county
                </span>
              )}
            </Typography>
            <AtiBar
              data={roLineData}
              //   teste={teste}
              lang={props.lang}
              //   color="rgb(60, 153, 220)"
              color_second="#e67e22"
            />
          </div>
        </Container>
      </Container>

      <Container style={{ maxWidth: 800, textAlign: "left" }}>
        <Divider style={{ marginTop: 40, marginBottom: 40 }} />

        <Typography
          style={{ marginTop: 20, marginBottom: 10, color: "grey" }}
          variant="subtitle2"
        >
          <strong> {props.lang === "ro" ? "Surse:" : "Sources"}</strong>
        </Typography>
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="https://data.gov.ro/dataset/transparenta-covid"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          {props.lang === "ro"
            ? "1. data.gov.ro - Transparenta COVID-19 - CC-BY 4.0"
            : "1. data.gov.ro - COVID-19 Transparency - CC-BY 4.0"}
        </Typography>

        <br />
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="http://www.ms.ro/comunicate/"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          {props.lang === "ro"
            ? "2. Ministerul sănătății"
            : "2. Romanian Health Ministry"}
        </Typography>

        <br />
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="http://www.dsu.mai.gov.ro/"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          {props.lang === "ro"
            ? "3. Departamentul pentru Situații de Urgență"
            : "3. Romanian Department For Emergency Situations"}
        </Typography>

        <Typography
          style={{ marginTop: 0, color: "grey", textDecoration: "none" }}
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          {props.lang === "ro"
            ? "4. Monitorizarea presei"
            : "4. Press monitoring"}
        </Typography>
        <Divider style={{ marginBottom: 40, marginTop: 40 }} />

        <div style={{ textAlign: "center", marginBottom: 40 }}>
          <Typography variant="subtitle2">Tech by</Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://addictad.ro"
          >
            <img
              style={{ height: 16, marginTop: 5 }}
              src="/images/logo-400px.png"
              alt=""
            />
          </a>
        </div>
      </Container>
    </div>
  );
}

export default Vaccinare;
