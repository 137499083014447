import { CssBaseline } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Routes from "./routes/Routes";
import * as serviceWorker from "./serviceWorker";
const theme = createMuiTheme({
  palette: {
    // type: 'dark',
    primary: {
      main: "#cbcbcb",
    },
    secondary: {
      main: "#cb181d",
    },
  },
  overrides: {
    MuiSlider: {
      root: {
        color: "Crimson",
        height: 8,
      },
      thumb: {
        height: 24,
        width: 24,
        backgroundColor: "rgb(248, 248, 248)",
        border: "4px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
          boxShadow: "inherit",
        },
      },
      active: {},
      valueLabel: {
        left: "calc(-50% + 4px)",
      },
      track: {
        height: 6,
        borderRadius: 4,
      },
      rail: {
        height: 6,
        // borderRadius: 4,
        backgroundColor: "#cdcdcd",
      },
      mark: {
        backgroundColor: "Crimson",
        height: 14,
        width: 4,
        marginTop: -4,
        borderRadius: 10,
      },
      markActive: {
        backgroundColor: "#444",
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline>
      <Routes />
    </CssBaseline>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
