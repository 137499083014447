import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import ErrorIcon from "@material-ui/icons/Error";
import React from "react";
import { Link } from "react-router-dom";
import "./App.css";
import ChipMenu from "./components/ChipMenu";
import Header from "./components/common/Header";

export default function SurseOficiale(props) {
  return (
    <div className="App" style={{ backgroundColor: "#f8f8f8" }}>
      <Typography
        variant="body2"
        style={{
          fontSize: 12,
          backgroundColor: "#dd1b14",
          color: "white",
          padding: 4,
          width: "100%",
        }}
      >
        {props.lang === "ro"
          ? "Această aplicație folosește cookies pentru a colecta statistici de utilizare. Prin folosirea aplicației îți exprimi acordul cu "
          : "This application uses cookies to collect usage statistics. By using the application you accept "}
        <Link
          style={{ color: "white", margin: 0 }}
          to={props.lang === "ro" ? "/cookies/" : "/en/cookies/"}
        >
          {props.lang === "ro"
            ? "politica noastră privind datele personale"
            : "our personal data policy"}
        </Link>
        .
      </Typography>
      <Container style={{ maxWidth: "800px" }}>
        <Header />
        <ChipMenu active={"surse"} lang={props.lang} />
        <Container
          style={{
            maxWidth: "800px",
            textAlign: "left",
            padding: 0,
            marginTop: 20,
            wrap: "wrap",
          }}
        >
          <Typography style={{ marginBottom: 10 }} variant="h5">
            <strong>
              {props.lang === "ro" ? "Despre aplicație" : "About the app"}
            </strong>
          </Typography>

          <Container
            style={{
              maxWidth: "800px",
              textAlign: "left",
              padding: 0,
              marginTop: 10,
            }}
          >
            {props.lang === "ro" && (
              <Typography
                style={{
                  textAlign: "left",
                  marginTop: 20,
                  fontWeight: 700,
                }}
              >
                <Link
                  to="/scrisoare_deschisa_ian_2021/"
                  style={{
                    textDecoration: "none",
                    color: "Crimson",
                    fontSize: 14,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ErrorIcon style={{ marginRight: 5 }} />
                  Scrisoare deschisă pentru publicarea completă a datelor
                  privind COVID-19 pe teritoriul României
                </Link>
              </Typography>
            )}
            <Typography
              style={{ fontWeight: 400, marginBottom: 10, marginTop: 20 }}
              variant="subtitle1"
            >
              {props.lang === "ro"
                ? "Aceasta este o aplicație web de monitorizare a pandemiei COVID-19, cu accent pe România."
                : "This is a web app monitoring the COVID-19 pandemic, with a focus on Romania."}
            </Typography>
            <Typography
              style={{ fontWeight: 400, marginBottom: 10 }}
              variant="subtitle1"
            >
              {props.lang === "ro"
                ? "Vizualizăm informații oficiale și centralizăm fluxuri de știri din fiecare județ."
                : "We visualize official information and centralize local news feeds from every county."}
            </Typography>

            <ul>
              <li>
                <Typography
                  style={{ fontWeight: 400, marginBottom: 10 }}
                  variant="subtitle1"
                >
                  <Link
                    style={{ color: "#333", fontWeight: 700 }}
                    to={props.lang === "ro" ? "/eu/" : "/en/eu/"}
                  >
                    {props.lang === "ro"
                      ? "Epidemia pe glob"
                      : "Worldwide epidemic"}
                  </Link>{" "}
                  {props.lang === "ro"
                    ? "afișează hărți și grafice cu numărul de cazuri din ultimele 10 zile."
                    : "shows maps and charts with the number of cases in the last 10 days."}
                </Typography>
              </li>
              <li>
                <Typography
                  style={{ fontWeight: 400, marginBottom: 10 }}
                  variant="subtitle1"
                >
                  <Link
                    style={{ color: "#333", fontWeight: 700 }}
                    to={props.lang === "ro" ? "/" : "/en/"}
                  >
                    {props.lang === "ro" ? "Epidemia în RO" : "Epidemic in RO"}
                  </Link>{" "}
                  {props.lang === "ro"
                    ? "afișează evoluția bolii în fiecare județ, alături de fluxuri din presa locală."
                    : "shows the evolution of the disease in every county, along with corresponding local news feeds."}
                </Typography>
              </li>
              <li>
                <Typography
                  style={{ fontWeight: 400, marginBottom: 10 }}
                  variant="subtitle1"
                >
                  <Link
                    style={{ color: "#333", fontWeight: 700 }}
                    to={props.lang === "ro" ? "/teste/" : "/en/teste/"}
                  >
                    {props.lang === "ro" ? "Teste" : "Tests"}
                  </Link>{" "}
                  {props.lang === "ro"
                    ? "urmărește măsurile luate de autorități: numărul de teste efectuate în fiecare zi, numărul de sancțiuni aplicate, traficul la frontieră și apelurile de urgență."
                    : "tracks measures taken by authorities: daily testing figures, sanctions applied, border traffic and emergency calls."}
                </Typography>
              </li>
              <li>
                <Typography
                  style={{ fontWeight: 400, marginBottom: 10 }}
                  variant="subtitle1"
                >
                  <Link
                    style={{ color: "#333", fontWeight: 700 }}
                    to={props.lang === "ro" ? "/presa/" : "/en/local-media/"}
                  >
                    {props.lang === "ro" ? "Știri locale" : "Local news"}
                  </Link>{" "}
                  {props.lang === "ro"
                    ? "urmărește 290 de publicații și îți arată județele în care apar cel mai frecvent știri despre virus, evoluția lor în timp și fluxuri cu știrile propriu-zise."
                    : "follows 290 publications, displays the frequency of coronavirus stories and lists the latest titles."}
                </Typography>
              </li>
            </ul>

            <Typography
              style={{ fontWeight: 400, marginBottom: 10 }}
              variant="subtitle1"
            >
              {props.lang === "ro"
                ? "Proiectul este realizat de"
                : "The project is made by"}{" "}
              <a
                style={{ color: "#333", fontWeight: 700 }}
                href={
                  props.lang === "ro"
                    ? "https://casajurnalistului.ro/"
                    : "https://casajurnalistului.ro/eng/"
                }
              >
                Casa Jurnalistului
              </a>
              {props.lang === "ro"
                ? ", cu sprijin de la"
                : " with support from"}{" "}
              <a
                style={{ color: "#333", fontWeight: 700 }}
                href="https://addictad.ro/"
              >
                addictad
              </a>
              {props.lang === "ro" ? " și" : " and"}{" "}
              <a
                style={{ color: "#333", fontWeight: 700 }}
                href="https://inclusiv.ro/"
              >
                Inclusiv
              </a>
              .
            </Typography>
            <Typography
              style={{ fontWeight: 400, marginBottom: 20 }}
              variant="subtitle1"
            >
              {props.lang === "ro"
                ? "Susținem transparența instituțiilor și cerem date care să nu mai conțină erori sau lipsuri."
                : "We support the transparency of official institutions and request open data, with minimal errors and omissions."}
            </Typography>

            <Typography
              style={{ fontWeight: 400, marginBottom: 20 }}
              variant="subtitle1"
            >
              Feedback →{" "}
              <a
                style={{ color: "#333", fontWeight: 700 }}
                href="mailto:hortensia@casajurnalistului.ro"
              >
                hortensia@casajurnalistului.ro
              </a>
            </Typography>
          </Container>

          <Divider style={{ marginBottom: 30, marginTop: 30 }} />

          <Typography style={{ marginBottom: 10 }} variant="h5">
            <strong>{props.lang === "ro" ? "Echipa" : "The team"}</strong>
          </Typography>

          <Container
            style={{
              maxWidth: "800px",
              textAlign: "left",
              padding: 0,
              marginTop: 10,
            }}
          >
            <Typography
              style={{
                fontWeight: 400,
                marginBottom: 10,
                marginTop: 20,
                fontWeight: 700,
              }}
              variant="subtitle1"
            >
              {props.lang === "ro"
                ? "Constantin Barbu - data journalist"
                : "Constantin Barbu - data journalist"}
            </Typography>
            <Typography
              style={{
                fontWeight: 400,
                marginBottom: 10,
                marginTop: 0,
                fontWeight: 700,
              }}
              variant="subtitle1"
            >
              {props.lang === "ro"
                ? "Vlad Ursulean - reporter"
                : "Vlad Ursulean - reporter"}
            </Typography>
          </Container>

          <Divider style={{ marginBottom: 30, marginTop: 30 }} />

          <Typography style={{ marginBottom: 10 }} variant="h5">
            <strong>
              {props.lang === "ro" ? "Surse oficiale" : "Official sources"}
            </strong>
          </Typography>

          <Typography style={{ fontWeight: 400 }} variant="subtitle1">
            {props.lang === "ro"
              ? "Pentru graficele și hărțile din această pagină folosim informații din sursele oficiale de mai jos. Orice informație preluată din presă este confruntată cu informațiile oficiale disponibile."
              : "For the charts and maps on this page we use information from the official sources below. Any information taken from the media is confronted with the official information available."}
          </Typography>
          <Typography
            style={{ fontWeight: 400, marginBottom: 20, marginTop: 20 }}
            variant="body1"
          >
            {props.lang === "ro"
              ? "Pentru a te ține la curent cu situația, ai la dispoziție următoarele surse oficiale de informație:"
              : "To keep up to date with the situation, you have the following official sources of information at your disposal:"}
          </Typography>
          <ul>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  marginBottom: 10,
                  // textDecoration: "none",
                  color: "#333",
                }}
                variant="body1"
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.stirioficiale.ro/"
              >
                StiriOficiale.ro
              </Typography>
            </li>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  // textDecoration: "none",
                  color: "#333",
                }}
                variant="body1"
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.ms.ro/comunicate/"
              >
                {props.lang === "ro"
                  ? "Ministerul Sănătății"
                  : "Romanian Ministry of Health"}
              </Typography>
            </li>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  paddingTop: 10,
                  // textDecoration: "none",
                  color: "#333",
                }}
                variant="body1"
                target="_blank"
                // rel="noopener noreferrer"
                component="a"
                href="https://covid.solidaritatea-sanitara.ro/"
              >
                Federația Solidaritatea Sanitară{" "}
                {props.lang === "ro" ? "" : " - medical personnel union"}
              </Typography>
            </li>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  paddingTop: 10,
                  // textDecoration: "none",
                  color: "#333",
                }}
                variant="body1"
                target="_blank"
                rel="noopener noreferrer"
                component="a"
                href="https://www.mai.gov.ro/category/comunicate-de-presa/"
              >
                {props.lang === "ro"
                  ? "Ministerul Afacerilor Interne"
                  : "Romanian Ministry of Internal Affairs"}
              </Typography>
            </li>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  paddingTop: 10,
                  // textDecoration: "none",
                  color: "#333",
                }}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                component="a"
                href="https://instnsp.maps.arcgis.com/apps/opsdashboard/index.html#/5eced796595b4ee585bcdba03e30c127"
              >
                {props.lang === "ro"
                  ? "Institutul Național de Sănătate Publică"
                  : "Romanian National Institute for Public Health"}
              </Typography>
            </li>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  paddingTop: 10,
                  // textDecoration: "none",
                  color: "#333",
                }}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                component="a"
                href="http://www.dsu.mai.gov.ro/"
              >
                {props.lang === "ro"
                  ? "Departamentul pentru Situații de Urgență"
                  : "Department for Emergency Situations"}
              </Typography>
            </li>

            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  paddingTop: 10,
                  // textDecoration: "none",
                  color: "#333",
                }}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                component="a"
                href="https://www.ecdc.europa.eu/en/novel-coronavirus-china"
              >
                {props.lang === "ro"
                  ? "Centrul European pentru Prevenirea și Controlul Bolilor (engleză)"
                  : "European Centre for Disease Control (ECDC)"}
              </Typography>
            </li>
          </ul>

          <Typography
            style={{
              fontWeight: 400,
              paddingTop: 10,
              // textDecoration: "none",
              color: "#333",
            }}
            target="_blank"
            rel="noopener noreferrer"
            variant="body1"
          >
            {props.lang === "ro" ? (
              <span>
                Autoritățile recomandă cetățenilor să ia în considerare doar
                informațiile verificate prin sursele oficiale și să apeleze
                pentru recomandări și alte informații la linia{" "}
                <a style={{ color: "#333" }} href="tel:+40800800358">
                  <strong>TELVERDE – 0800.800.358</strong>
                </a>
                . Numărul TELVERDE nu este un număr de urgență, este o linie
                telefonică alocată strict pentru informarea cetățenilor.
              </span>
            ) : (
              <span>
                Authorities recommend that citizens and residents rely only on
                information verified from official sources. You can call the
                official information hotline{" "}
                <a style={{ color: "#333" }} href="tel:+40800800358">
                  <strong>TELVERDE – 0800.800.358</strong>
                </a>
                . This number should not be used for emergencies, but only for
                general information inquiries about the current measures in
                place.
              </span>
            )}
          </Typography>
          <br />
          <Typography
            style={{
              fontWeight: 400,
              // textDecoration: "none",
              color: "#333",
            }}
            variant="body1"
          >
            {props.lang === "ro" ? (
              <span>
                <strong>Românii aflați în străinătate</strong> pot solicita
                informații despre prevenirea și combaterea virusului la linia
                special dedicată lor{" "}
                <a style={{ color: "#333" }} href="tel:+40213202020">
                  <strong>+4021.320.20.20</strong>
                </a>
                .
              </span>
            ) : (
              <span>
                <strong>
                  Romanians citizens and foreigners with Romanian residency
                  currently abroad
                </strong>{" "}
                can request information on preventing and combating the virus at
                the hotline dedicated to them{" "}
                <a style={{ color: "#333" }} href="tel:+40213202020">
                  <strong>+4021.320.20.20</strong>
                </a>
                .
              </span>
            )}
          </Typography>
          <Divider style={{ marginBottom: 30, marginTop: 30 }} />
          <Typography style={{ marginBottom: 10 }} variant="h5">
            <strong>
              {props.lang === "ro" ? "Alte surse utile" : "Other sources"}
            </strong>
          </Typography>

          <Typography style={{ fontWeight: 400 }} variant="subtitle1">
            {props.lang === "ro"
              ? "Recomandăm și următoarele surse pentru informații la zi despre epidemie:"
              : "We also recommend the following sources for up-to-date information about the epidemic:"}
          </Typography>
          <ul>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  // textDecoration: "none",
                  color: "#333",
                }}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                component="a"
                href="https://www.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6"
              >
                {props.lang === "ro"
                  ? "Johns Hopkins CSSE - harta în timp real a epidemiei la nivel global"
                  : "Johns Hopkins CSSE - real-time map of the global epidemic"}
              </Typography>
            </li>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  // textDecoration: "none",
                  color: "#333",
                }}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                component="a"
                href="https://econ.ubbcluj.ro/coronavirus/"
              >
                {props.lang === "ro"
                  ? "Universitatea Babeș-Bolyai Cluj Napoca - COVID-19 - Romanian Economic Impact Monitor"
                  : "Babeș-Bolyai University, Cluj Napoca - COVID-19 - Romanian Economic Impact Monitor"}
              </Typography>
            </li>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  // textDecoration: "none",
                  color: "#333",
                }}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                component="a"
                href="https://covid19.geo-spatial.org/dashboard/main"
              >
                {props.lang === "ro"
                  ? "geo-spatial.org - monitorizare a epidemiei făcută de o echipă românească experimentată în vizualizărea datelor"
                  : "geo-spatial.org - epidemic monitoring dashboard made by a Romanian team experienced in data visualization"}
              </Typography>
            </li>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  // textDecoration: "none",
                  color: "#333",
                }}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                component="a"
                href="http://www.hotnews.ro/"
              >
                {props.lang === "ro"
                  ? "HotNews.ro - știri la zi despre epeidemie"
                  : "HotNews.ro - up-to-date news about the epidemic"}
              </Typography>
            </li>
            <li style={{ marginBottom: 10 }}>
              <Typography
                style={{
                  fontWeight: 700,
                  color: "#333",
                }}
                target="_blank"
                rel="noopener"
                variant="body1"
                component="a"
                href="https://www.libertatea.ro/subiect/coronavirus"
              >
                {props.lang === "ro"
                  ? "Libertatea.ro - anchete și analize despre epidemie"
                  : "Libertatea.ro - investigations and analysis about the epidemic"}
              </Typography>
            </li>
          </ul>
        </Container>
      </Container>

      <Container style={{ maxWidth: 1000, textAlign: "left" }}>
        {/* <Divider style={{ marginTop: 0, marginBottom: 30 }} />
        <Typography
          style={{ marginTop: 20, color: "grey" }}
          variant="subtitle2"
        >
          <strong>Surse:</strong>
        </Typography>
        <Typography
          style={{ marginTop: 10, color: "grey" }}
          component="a"
          href="https://instnsp.maps.arcgis.com/apps/opsdashboard/index.html#/5eced796595b4ee585bcdba03e30c127"
          target="_blank"
          rel="noopener noreferrer"
          variant="subtitle2"
        >
          1. Institutul Național de Sănătate Publică din România
        </Typography>
         */}
        <Divider style={{ marginBottom: 30, marginTop: 30 }} />

        <div style={{ textAlign: "center", marginBottom: 40 }}>
          <Typography variant="subtitle2">Tech by</Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://addictad.ro"
          >
            <img
              style={{ height: 16, marginTop: 5 }}
              src="/images/logo-400px.png"
              alt=""
            />
          </a>
        </div>
      </Container>
    </div>
  );
}
