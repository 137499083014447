import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AirlineSeatFlatIcon from "@material-ui/icons/AirlineSeatFlat";
import CheckIcon from "@material-ui/icons/Check";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import RssFeed from "@material-ui/icons/FormatListBulleted";
import Language from "@material-ui/icons/Language";
import Loyalty from "@material-ui/icons/Loyalty";
import PersonPinCircle from "@material-ui/icons/PersonPinCircle";
import PersonIcon from "@material-ui/icons/Person";
import React from "react";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  menuItem: {
    marginBottom: 10,
    marginTop: 10,
    marginRight: 6,
    paddingLeft: 2,
    paddingRight: 2,
  },
});

export default function ChipMenu(props) {
  const classes = useStyles();
  const [detalii, setDetalii] = React.useState(false);
  return (
    <div>
      <Container
        style={{
          maxWidth: "800px",
          textAlign: "left",
          padding: 0,
          marginTop: 20,
        }}
      >
        <Typography style={{ marginBottom: 10 }} variant="h5">
          <strong>
            {props.lang === "ro" ? "Date disponibile" : "Available data"}
          </strong>{" "}
        </Typography>
        <Typography
          style={{ fontWeight: 400, marginBottom: 10 }}
          variant="subtitle1"
        >
          {props.lang === "ro"
            ? "Alege un set de date sau află mai multe detalii:"
            : "Choose a data set or find out more details:"}
        </Typography>
      </Container>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "left",
          alignItems: "left",
        }}
      >
        <Link
          style={{ textDecoration: "none", margin: 0 }}
          to={props.lang === "ro" ? "/" : "/en/"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "ro" ? "Crimson" : "Transparent",
              color: props.active === "ro" ? "white" : "#444",
              border:
                props.active === "ro" ? "1px solid white" : "1px solid #cbcbcb",
            }}
            label={
              <strong>
                {props.lang === "ro" ? "epidemia în RO" : "epidemic in RO"}
              </strong>
            }
            icon={
              <PersonPinCircle
                style={{ color: props.active === "ro" ? "white" : "#444" }}
              />
            }
          />
        </Link>

        {/* <Link
          style={{ textDecoration: "none", margin: 0 }}
          to={props.lang === "ro" ? "/decese" : "/en/decese"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "decese" ? "Crimson" : "Transparent",
              color: props.active === "decese" ? "white" : "#444",
              border:
                props.active === "decese"
                  ? "1px solid white"
                  : "1px solid #cbcbcb",
            }}
            label={<strong>{props.lang === "ro" ? "decese" : "deaths"}</strong>}
            icon={
              <PersonIcon
                style={{
                  color: props.active === "decese" ? "white" : "#444",
                }}
              />
            }
          />
        </Link> */}

        {/* <Link
          style={{ textDecoration: "none", margin: 0 }}
          to={props.lang === "ro" ? "/vaccin" : "/en/vaccine"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "vaccin" ? "Crimson" : "Transparent",
              color: props.active === "vaccin" ? "white" : "#444",
              border: props.active === "vaccin" ? "1px solid white" : "1px solid #cbcbcb",
            }}
            label={
              <strong>
                {props.lang === "ro" ? "vaccinarea în RO" : "vaccination in RO"}
              </strong>
            }
            icon={
              <LocalHospitalIcon
                style={{ color: props.active === "vaccin" ? "white" : "#444" }}
              />
            }
          />
        </Link> */}

        <Link
          style={{ textDecoration: "none", margin: 0 }}
          to={props.lang === "ro" ? "/teste" : "/en/teste"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "teste" ? "Crimson" : "Transparent",
              color: props.active === "teste" ? "white" : "#444",
              border:
                props.active === "teste"
                  ? "1px solid white"
                  : "1px solid #cbcbcb",
            }}
            label={<strong>{props.lang === "ro" ? "teste" : "Tests"}</strong>}
            icon={
              <CheckIcon
                style={{ color: props.active === "teste" ? "white" : "#444" }}
              />
            }
          />
        </Link>

        <Link
          style={{ textDecoration: "none", margin: 0 }}
          to={props.lang === "ro" ? "/ati" : "/en/ati"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "ati" ? "Crimson" : "Transparent",
              color: props.active === "ati" ? "white" : "#444",
              border:
                props.active === "ati"
                  ? "1px solid white"
                  : "1px solid #cbcbcb",
            }}
            label={<strong>{props.lang === "ro" ? "ATI" : "ICU"}</strong>}
            icon={
              <AirlineSeatFlatIcon
                style={{ color: props.active === "ati" ? "white" : "#444" }}
              />
            }
          />
        </Link>

        <Link
          style={{ textDecoration: "none", margin: 0 }}
          to={props.lang === "ro" ? "/incidenta" : "/en/incidenta"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "incidenta" ? "Crimson" : "Transparent",
              color: props.active === "incidenta" ? "white" : "#444",
              border:
                props.active === "incidenta"
                  ? "1px solid white"
                  : "1px solid #cbcbcb",
            }}
            label={
              <strong>
                {props.lang === "ro"
                  ? "incidență la 14 zile"
                  : "14 day average"}
              </strong>
            }
            icon={
              <EqualizerIcon
                style={{
                  color: props.active === "incidenta" ? "white" : "#444",
                }}
              />
            }
          />
        </Link>

        <Link
          style={{ textDecoration: "none", margin: 0 }}
          to={props.lang === "ro" ? "/eu/" : "/en/eu/"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "globe" ? "Crimson" : "Transparent",
              color: props.active === "globe" ? "white" : "#444",
              border:
                props.active === "globe"
                  ? "1px solid white"
                  : "1px solid #cbcbcb",
            }}
            label={
              <strong>
                {props.lang === "ro" ? "cazuri în lume" : "cases worldwide"}
              </strong>
            }
            icon={
              <Language
                style={{ color: props.active === "globe" ? "white" : "#444" }}
              />
            }
          />
        </Link>

        <Link
          style={{ textDecoration: "none", margin: 0 }}
          to={props.lang === "ro" ? "/eu_deaths/" : "/en/eu_deaths/"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "globe_deaths" ? "Crimson" : "Transparent",
              color: props.active === "globe_deaths" ? "white" : "#444",
              border:
                props.active === "globe_deaths"
                  ? "1px solid white"
                  : "1px solid #cbcbcb",
            }}
            label={
              <strong>
                {props.lang === "ro" ? "decese în lume" : "deaths worldwide"}
              </strong>
            }
            icon={
              <Language
                style={{
                  color: props.active === "globe_deaths" ? "white" : "#444",
                }}
              />
            }
          />
        </Link>
        <Link
          style={{ textDecoration: "none", margin: 0 }}
          to={props.lang === "ro" ? "/vaccine_eu/" : "/en/vaccine_eu/"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "vac_eu" ? "Crimson" : "Transparent",
              color: props.active === "vac_eu" ? "white" : "#444",
              border:
                props.active === "vac_eu"
                  ? "1px solid white"
                  : "1px solid #cbcbcb",
            }}
            label={
              <strong>
                {props.lang === "ro"
                  ? "vaccinarea în UE"
                  : "vaccination in the EU"}
              </strong>
            }
            icon={
              <FavoriteIcon
                style={{ color: props.active === "vac_eu" ? "white" : "#444" }}
              />
            }
          />
        </Link>

        {/* <Link
          style={{ textDecoration: "none", margin: 0 }}
          to={props.lang === "ro" ? "/stare-urgenta/" : "/en/emergency-state/"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "carantina" ? "Crimson" : "Transparent",
              color: props.active === "carantina" ? "white" : "#444",
              border: props.active === "carantina" ? "1px solid white" : "1px solid #cbcbcb",
            }}
            label={
              <strong>
                {props.lang === "ro" ? "sancțiuni" : "fines & borders"}
              </strong>
            }
            icon={
              <Error
                style={{
                  color: props.active === "carantina" ? "white" : "#444",
                }}
              />
            }
          />
        </Link> */}

        <Link
          style={{ textDecoration: "none" }}
          to={props.lang === "ro" ? "/presa/" : "/en/local-media/"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "presa" ? "Crimson" : "Transparent",
              color: props.active === "presa" ? "white" : "#444",
              border:
                props.active === "presa"
                  ? "1px solid white"
                  : "1px solid #cbcbcb",
            }}
            label={
              <strong>
                {props.lang === "ro" ? "știri locale" : "local news"}
              </strong>
            }
            icon={
              <RssFeed
                style={{ color: props.active === "presa" ? "white" : "#444" }}
              />
            }
          />
        </Link>

        {/* <Chip
          className={classes.menuItem}
          style={{
            backgroundColor: "Transparent",
            color: "#444",
            border: "1px solid #cbcbcb",
          }}
          label={
            <strong>{props.lang === "ro" ? "detalii" : "more details"}</strong>
          }
          onClick={() => setDetalii(!detalii)}
          icon={
            detalii ? (
              <UnfoldLess style={{ color: "#444" }} />
            ) : (
              <UnfoldMore style={{ color: "#444" }} />
            )
          }
        /> */}

        {/* <Link
          style={{ textDecoration: "none" }}
          to={props.lang === "ro" ? "/subiecte/" : "/en/topics/"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "subiecte" ? "Crimson" : "Transparent",
              color: props.active === "subiecte" ? "white" : "#444",
              border: props.active === "subiecte" ? "1px solid white" : "1px solid #cbcbcb",
            }}
            label={
              <strong>
                {props.lang === "ro"
                  ? "subiecte monitorizate"
                  : "monitored topics"}
              </strong>
            }
            icon={
              <Pageview
                style={{
                  color: props.active === "subiecte" ? "white" : "#444",
                }}
              />
            }
          />
        </Link> */}
        {/* <Link
          style={{ textDecoration: "none" }}
          to={props.lang === "ro" ? "/experiment/" : "/en/experiment/"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "experiment" ? "Crimson" : "Transparent",
              color: props.active === "experiment" ? "white" : "#444",
              border:
                props.active === "experiment" ? "1px solid white" : "1px solid #cbcbcb",
            }}
            label={<strong>experiment</strong>}
            icon={
              <AllInclusiveIcon
                style={{
                  color: props.active === "experiment" ? "white" : "#444",
                }}
              />
            }
          />
        </Link>
         */}
        <Link
          style={{ textDecoration: "none" }}
          to={props.lang === "ro" ? "/despre/" : "/en/about/"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor:
                props.active === "surse" ? "Crimson" : "Transparent",
              color: props.active === "surse" ? "white" : "#444",
              border:
                props.active === "surse"
                  ? "1px solid white"
                  : "1px solid #cbcbcb",
            }}
            label={<strong>{props.lang === "ro" ? "despre" : "about"}</strong>}
            icon={
              <Loyalty
                style={{ color: props.active === "surse" ? "white" : "#444" }}
              />
            }
          />
        </Link>

        <Link
          style={{ textDecoration: "none" }}
          to={props.lang === "ro" ? "/en/" : "/"}
        >
          <Chip
            className={classes.menuItem}
            style={{
              backgroundColor: "Transparent",
              color: "#444",
              border: "1px solid #cbcbcb",
            }}
            label={
              <strong>{props.lang === "ro" ? "English" : "română"}</strong>
            }
            avatar={
              <Avatar style={{ backgroundColor: "transparent", fontSize: 20 }}>
                {props.lang === "ro" ? (
                  <span role="img">🇬🇧</span>
                ) : (
                  <span role="img">🇷🇴</span>
                )}
              </Avatar>
            }
            // icon={
            //   <Loyalty
            //     style={{ color: props.active === "surse" ? "white" : "#444" }}
            //   />
            // }
          />
        </Link>
      </div>

      <Divider style={{ marginBottom: 40, marginTop: 40 }} />
    </div>
  );
}
